import React, { Component } from 'react'
import { Col, Row, Button } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import { CLINICAL_TRIAL_PHASE, INDICATIONS, LINE_OF_THERAPY, REQUEST_TYPE, SECONDARY_REQUEST_TYPE, INTERVENTIONAL_STUDY_CHECK, STUDY_PRIORITY, THERAPEUTIC_AREA, TRIAL_CATEGORY_2_STAGE_2_SUBMISSION_STATUS } from '../../../consts';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { INITIAL_SUBMISSION } from '../../../mapping';
import Loader from '../../common/loader/Loader';
import { dateTimeformat, getUserInfo } from '../../../utility/helperFunctions';
import UserContext from '../../../context/UserContext';
import './InitialSubmission.css'
export default class InitialSubmission extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },        
        investigator:this.props.submissionDetails.concepts.length>0 ? this.props.submissionDetails.concepts[0].investigatorname :"",
        stage: this.props.submissionDetails.stage || "",
        submissionStatus : this.props.submissionDetails.status || "",
        extCountry : this.props.submissionDetails.concepts[0]?.country || "",
        // extUniqueSubmissionIdentifier : this.props.submissionDetails.unique_submission_identifier || "",
        shorthandTitle : this.props.submissionDetails.shorthand_title || "",
        type_of_material_provided: this.props.submissionDetails.type_of_material_provided || "",
        lilly_support: this.props.submissionDetails.lilly_support || "",
        country1 :  this.props.submissionDetails.country_1 || "",
        country2 : this.props.submissionDetails.country_2 || "",
        extTrialCategory : this.props.submissionDetails.trial_category || "",
        country3 : this.props.submissionDetails.country_3 || "",
        // SOSLHelpField : this.props.submissionDetails.sosl_help_field || "",
        country4 : this.props.submissionDetails.country_4 || "",
        requestType :this.props.submissionDetails.concepts.length>0 ? this.props.submissionDetails.concepts[0].request_type : "",
        secondary_request_type: this.props.submissionDetails.concepts.length>0  ? this.props.submissionDetails.concepts[0].secondary_request_type : "None",
        extTherapeuticArea : this.props.submissionDetails.concepts.length>0 ? this.props.submissionDetails.concepts[0].therapeutic_area :  "",
        extCompound :this.props.submissionDetails.concepts.length>0 ? this.props.submissionDetails.concepts[0].compound_id : "",
        legacyTherapeuticArea : this.props.submissionDetails.legacy_therapeutic_area || "",
        compound1 : this.props.submissionDetails.compound_1 || "",
        addntlCompounds : [],
        
        extTumorIndication : this.props.submissionDetails.indication || "",
        compound2 : this.props.submissionDetails.compound_2 || "",
        extOtherIndication : this.props.submissionDetails.concepts.length>0 ? this.props.submissionDetails.concepts[0]?.other_indication :"",
        investigatorID :this.props.submissionDetails.concepts.length>0 ? {name:"INVID-"+ (""+this.props.submissionDetails.investigator?.id).padStart(7,'0')+ " | " + this.props.submissionDetails.investigator_last_name,value:this.props.submissionDetails.investigator?.id} : "",
        extIndication : this.props.submissionDetails.concepts.length>0 && this.props.submissionDetails.concepts[0]?.indication !== "" ? this.props.submissionDetails.concepts[0]?.indication?.trim().split(';').join()?.split(',').map(obj => { return {value: obj,label: obj}}) : null,
        protocolNumber : this.props.submissionDetails.protocol_no || "",
        extClinicalTrialPhase : this.props.submissionDetails.clinical_trail_phase?.length>0 ? this.props.submissionDetails.clinical_trail_phase?.trim().split(';').join()?.split(',').map(obj => { return {value: obj,label: obj}}) : null,
        lillyMedicalMonitor : this.props.submissionDetails.lilly_medical_monitor || "",
        last_modified_by : this.props.submissionDetails.last_modified_by || "",
        studyPriority : this.props.submissionDetails.study_priority || "",
        impactNumber : this.props.submissionDetails.impact_no || "",
        is_feasibility_assessment_sent:this.props.submissionDetails.is_feasibility_assessment_sent||false,
        nctNumber : this.props.submissionDetails.nct_study || "",
        trialAlias : this.props.submissionDetails.trial_alias || "",
        localRegulatoryApprovalNumber : this.props.submissionDetails.local_regulatory_no || "",
        createdBy : this.props.submissionDetails.created_by || "",
        agreementNumber: this.props.submissionDetails.agreement_no || "",
        extPrimaryMSLEmail: this.props.submissionDetails.primary_msl_email || "",
        extLineofTherapy: this.props.submissionDetails.line_of_therapy || "",
        additionalMSLEmail: this.props.submissionDetails.additional_msl_email || "",
        studyTitle: this.props.submissionDetails.study_title || "",
        notes:this.props.submissionDetails.notes,
        doNotSendAutomatedEmails: this.props.submissionDetails.doNotSendAutomatedEmails || false,
        is_sub_study:this.props.submissionDetails.is_sub_study || false,
        parent_study_submission_number: this.props.submissionDetails.parent_study_submission_number || "",
        assignedTo : "",
        additionalCoordinator : "",
        extPrimaryMSL : "",
        additionalMSL : "",
        compoundList: [],
        // newCompoundList: [],
        compoundListMultiSelect: [],
        countryList: [],
        userData: [],
        usersList: [],
        parentSubmissionIdList: [],
        investigatorList:[],
        successModal: false,
        errorModal: false,
        stageList:this.props.submissionDetails.trial_category ? Object.keys(TRIAL_CATEGORY_2_STAGE_2_SUBMISSION_STATUS[this.props.submissionDetails.trial_category]) : "",
        statusList: this.props.submissionDetails.trial_category ? TRIAL_CATEGORY_2_STAGE_2_SUBMISSION_STATUS[this.props.submissionDetails.trial_category][this.props.submissionDetails.stage] : "",
        patchObject: {"id": this.props.submissionDetails.id,"last_modified_by":this.context.userSystemId},
        oldStage: this.props.submissionDetails.stage,
        oldStatus : this.props.submissionDetails.status,
        interventional_study: this.props.submissionDetails.interventional_study || "",
        messageView:{message:"Please review the Interventional Study Field"},
        requesting_research_funding: this.props.submissionDetails.requesting_research_funding || "",
}
    
    get inputFieldsArray() { 
        return [
        {
            label: "Investigator",
            type: "text",
            placeholder: "Enter Investigator",
            name:"investigator",
            disabled: true
        },
        {
            label: "Stage",
            type: "dropdown",
            placeholder: "Select Stage",
            name:"stage",
            options: this.state.stageList,
            onSelect: this.onStageChange
        },
        {
            label: "Investigator ID",
            type: "searchableDropdown",
            placeholder: "Enter Investigator ID",
            name:"investigatorID",
            options: this.state.investigatorList,
            onSelect: this.onInvestigatorChange
        },
        {
            label: "Submission Status",
            type: "dropdown",
            placeholder: "Select Status",
            name:"submissionStatus",
            options: this.state.statusList
        },
        {
            label: "Assigned To",
            type: "searchableDropdown",
            placeholder: "Select User",
            name:"assignedTo",
            options: this.state.usersList,
            onSelect: this.onSearchSelect
        },
        {
            label: "[EXT] Country",
            type: "searchableDropdown",
            placeholder: "Select Country",
            name:"extCountry",
            options: this.state.countryList,
            onSelect: this.onSearchSelect
        },
        {
            label: "Additional Coordinator",
            type: "searchableDropdown",
            placeholder: "Select Coordinator",
            name:"additionalCoordinator",
            options: this.state.usersList,
            onSelect: this.onSearchSelect
        },
        // {
        //     label: "[EXT] Unique Submission Identifier",
        //     type: "textarea",
        //     placeholder: "Enter USI",
        //     name:"extUniqueSubmissionIdentifier",
        //     disabled: true
        // },
        {
            label: "Country 1",
            placeholder: "Select Country",
            type: "searchableDropdown",
            name:"country1",
            options: this.state.countryList,
            onSelect: this.onSearchSelect
        },
        {
            label: "[EXT] Primary MSL",
            type: "searchableDropdown",
            placeholder: "Enter Primary MSL",
            name:"extPrimaryMSL",
            options: this.state.usersList,
            onSelect: this.onMSLChange
        },
        {
            label: "Country 2",
            type: "searchableDropdown",
            name:"country2",
            placeholder: "Select Country",
            options: this.state.countryList,
            onSelect: this.onSearchSelect
        },
        {
            label: "[EXT] Primary MSL Email",
            type: "email",
            name:"extPrimaryMSLEmail",
            disabled: true
        },
        {
            label: "Country 3",
            type: "searchableDropdown",
            name:"country3",
            placeholder: "Select Country",
            options: this.state.countryList,
            onSelect: this.onSearchSelect
        },
        {
            label: "Additional MSL",
            type: "searchableDropdown",
            placeholder: "Select MSL",
            name:"additionalMSL",
            options: this.state.usersList,
            onSelect: this.onMSLChange
        },
        {
            label: "Country 4",
            type: "searchableDropdown",
            name:"country4",
            placeholder: "Select Country",
            options: this.state.countryList,
            onSelect: this.onSearchSelect
        },
        {
            label: "Additional MSL Email",
            type: "text",
            name:"additionalMSLEmail",
            disabled: true
        },
        {
            label: "Study Title",
            type: "textarea",
            name:"studyTitle",
        },
        
        {
            label: "Lilly Medical Monitor",
            type: "text",
            placeholder: "Enter Lilly Medical Monitor",
            name: "lillyMedicalMonitor",
        },
        {
            label: "Shorthand Title",
            type: "text",
            name: "shorthandTitle",
            placeholder: "Enter Shorthand Title",
        },
        {
            label: "[EXT] Therapeutic Area",
            type: "dropdown",
            placeholder: "Select Therapeutic Area",
            name: "extTherapeuticArea",
            options: THERAPEUTIC_AREA
        },
        {
            label: "[EXT] Requesting Research Funding",
            type: "dropdown",
            placeholder: "Select",
            name: 'requesting_research_funding',
            options: ["Yes", "No"]
        },
        {
            label: "[EXT] Trial Category",
            type: "dropdown",
            placeholder: "Select Trial Category",
            name: "extTrialCategory",
            options: Object.keys(TRIAL_CATEGORY_2_STAGE_2_SUBMISSION_STATUS),
            onSelect: this.onTrailChange
        },
        {
            label: "[EXT] Compound",
            type: "searchableDropdown",
            name: "extCompound",
            options: this.state.compoundList,
            onSelect: this.onSearchSelect
        },
        {
            label: "Interventional Study *",
            type: "dropdown",
            placeholder: "Select Interventional Study",
            name: "interventional_study",
            options: ["None", "Yes", "No"]
        },
        {
            label: "Additional Compounds",
            type: "multi-select",
            placeholder: "Select Additional Compounds",
            name: "addntlCompounds",
            options: this.state.compoundListMultiSelect,
        },     
        // {
        //     label: "SOSL Help Field",
        //     type: "textarea",
        //     name:"SOSLHelpField",
        //     disabled:true,
            
      
        // },
        {
            label: "Primary Request Type",
            type: "dropdown",
            placeholder: "Enter Select Primary Request Type",
            name:"requestType",
            options: REQUEST_TYPE
        },{
            label: "Type of Material Provided",
        },
        {
            label: "Secondary Request Type",
            type: "dropdown",
            placeholder: "Select Secondary Request Type",
            name:"secondary_request_type",
            options: SECONDARY_REQUEST_TYPE,
        },{
            label: "Lilly Support",
        },
        {
            label: "[EXT] Clinical Trial Phase",
            type: "multi-select",
            placeholder: "Select Clinical Trial Phase",
            name: "extClinicalTrialPhase",
            options: CLINICAL_TRIAL_PHASE
        },
        {
            label: "[EXT] Other Indication",
            type: "text",
            placeholder: "Enter Other Indication",
            name:"extTumorIndication",
            helptext:"Indication of a concept"

        },
        {
            label: "Legacy Therapeutic Area",
            type: "text",
            placeholder: "Enter Legacy Therapeutic Area",
            name:"legacyTherapeuticArea",
        },
        {
            label: "[EXT] Tumors",
            type: "multi-select",
            placeholder: "Select Tumors",
            name: "extIndication",
            options: INDICATIONS,
            disabled: this.state.extTherapeuticArea !== "Oncology",
            helptext:"Oncology Only: Select tumor(s) related to submission"
        }, 
        
        {
            label: "Trial Alias",
            type: "text",
            placeholder: "Enter Trial Alias",
            name:"trialAlias",
            disabled:this.state.is_sub_study === true
        },
        // {
        //     label: "Compound 1",
        //     type: "searchableDropdown",
        //     placeholder: "Select Compound",
        //     options: this.state.newCompoundList,
        //     onSelect: this.onSearchSelect,
        //     name:"compound1",
        // },
        {
            label: "[EXT] Other Tumors",
            type: "text",
            placeholder: "Enter Other Tumors",
            name: "extOtherIndication",
            helptext:"Oncology Only: Define the tumor"
        },
        {
            label: "Local Regulatory Approval Number",
            type: "text",
            placeholder: "Enter Approval Number",
            name:"localRegulatoryApprovalNumber",
        },
        {
            label: "[EXT] Line of Therapy",
            type: "dropdown",
            placeholder: "Select Line of Therapy",
            name:"extLineofTherapy",
            options: LINE_OF_THERAPY
        },
        // {
        //     label: "Compound 2",
        //     type: "searchableDropdown",
        //     placeholder: "Select Compound",
        //     options: this.state.newCompoundList,
        //     onSelect: this.onSearchSelect,
        //     name:"compound2",
        // },
        {
            label: "Protocol Number",
            type: "text",
            placeholder: "Enter Protocol Number",
            name:"protocolNumber",
        },
        {
            label: "Study Priority",
            type: "dropdown",
            placeholder: "Select Study Priority",
            name: "studyPriority",
            options: STUDY_PRIORITY
        },{
            label: "NCT #",
            type: "number",
            placeholder: "Enter NCT #",
            name:"nctNumber",
            min: 0
        },
        {
            label: "Is this a sub study",
            name:  "is_sub_study",
            type: "checkbox",
            helptext:"Check the field if this study is a child of a parent study"
        },{
            label: "Agreement Number",
            type: "text",
            placeholder: "Enter Agreement Number",
            name:"agreementNumber",
        },
        {
            label: "Parent study submission number",
            name:  "parent_study_submission_number",
            type: "searchableDropdown",
            placeholder: "Select Submission Number",
            hidden: this.state.is_sub_study === false,
            options: this.state.parentSubmissionIdList,
            onSelect: this.onSearchSelect
        },
        {
            label: "Impact Number",
            type: "text",
            placeholder: "Enter Impact Number",
            name:"impactNumber",
            disabled:this.state.is_sub_study === true
        },{
            label: "Do not send automated emails",
            name:  "doNotSendAutomatedEmails",
            type: "checkbox",
        },{
            label: "Notes",
            type: "textarea",
            name:"notes",
        },
        {
            label: "Has Feasibility Assessment been sent to PD?",
            type: "checkbox",
            name:"is_feasibility_assessment_sent",
        },{
            //
        },
        {
            label: "Last Modified By",
            type: "text",
            name:"last_modified_by",
            className:"createUpdateByfontsize",
            disabled: true
        },{
            //
        },
        {
            label: "Created By",
            type: "text",
            name:"createdBy",
            className:"createUpdateByfontsize",
            disabled: true,
            
        },
    ];
}
    get inputFieldsArrayLeftCol(){
        return [
            {
                label: "Investigator",
                type: "text",
                placeholder: "Enter Investigator",
                name:"investigator",
                disabled: true
            },
            {
                label: "Investigator ID",
                type: "searchableDropdown",
                placeholder: "Enter Investigator ID",
                name:"investigatorID",
                options: this.state.investigatorList,
                onSelect: this.onInvestigatorChange
            },{
                label: "Assigned To",
                type: "searchableDropdown",
                placeholder: "Select User",
                name:"assignedTo",
                options: this.state.usersList,
                onSelect: this.onSearchSelect
            },
            {
                label: "Additional Coordinator",
                type: "searchableDropdown",
                placeholder: "Select Coordinator",
                name:"additionalCoordinator",
                options: this.state.usersList,
                onSelect: this.onSearchSelect
            },{
                label: "[EXT] Primary MSL",
                type: "searchableDropdown",
                placeholder: "Enter Primary MSL",
                name:"extPrimaryMSL",
                options: this.state.usersList,
                onSelect: this.onMSLChange
            },
            {
                label: "[EXT] Primary MSL Email",
                type: "email",
                name:"extPrimaryMSLEmail",
                disabled: true
            },{
                label: "Additional MSL",
                type: "searchableDropdown",
                placeholder: "Select MSL",
                name:"additionalMSL",
                options: this.state.usersList,
                onSelect: this.onMSLChange
            },{
                label: "Additional MSL Email",
                type: "text",
                name:"additionalMSLEmail",
                disabled: true
            },{
                label: "Lilly Medical Monitor",
                type: "text",
                placeholder: "Enter Lilly Medical Monitor",
                name: "lillyMedicalMonitor",
            },{
                label: "[EXT] Therapeutic Area",
                type: "dropdown",
                placeholder: "Select Therapeutic Area",
                name: "extTherapeuticArea",
                options: THERAPEUTIC_AREA
            },
            {
                label: "[EXT] Requesting Research Funding",
                type: "dropdown",
                placeholder: "Select",
                name: 'requesting_research_funding',
                options: ["Yes", "No"]
            },{
                label: "Interventional Study *",
                type: "dropdown",
                placeholder: "Select Interventional Study",
                name: "interventional_study",
                options: ["None", "Yes", "No"]
            },{
                label: "Primary Request Type",
                type: "dropdown",
                placeholder: "Enter Select Primary Request Type",
                name:"requestType",
                options: REQUEST_TYPE
            },{
                label: "Secondary Request Type",
                type: "dropdown",
                placeholder: "Select Secondary Request Type",
                name:"secondary_request_type",
                options: SECONDARY_REQUEST_TYPE,
            },{
                label: "[EXT] Clinical Trial Phase",
                type: "multi-select",
                placeholder: "Select Clinical Trial Phase",
                name: "extClinicalTrialPhase",
                options: CLINICAL_TRIAL_PHASE
            },{
                label: "Legacy Therapeutic Area",
                type: "text",
                placeholder: "Enter Legacy Therapeutic Area",
                name:"legacyTherapeuticArea",
            },{
                label: "Trial Alias",
                type: "text",
                placeholder: "Enter Trial Alias",
                name:"trialAlias",
                disabled:this.state.is_sub_study === true
            },{
                label: "Local Regulatory Approval Number",
                type: "text",
                placeholder: "Enter Approval Number",
                name:"localRegulatoryApprovalNumber",
            },{
                label: "Protocol Number",
                type: "text",
                placeholder: "Enter Protocol Number",
                name:"protocolNumber",
            },{
                label: "NCT #",
                type: "number",
                placeholder: "Enter NCT #",
                name:"nctNumber",
                min: 0
            },
            {
                label: "Agreement Number",
                type: "text",
                placeholder: "Enter Agreement Number",
                name:"agreementNumber",
            },{
                label: "Impact Number",
                type: "text",
                placeholder: "Enter Impact Number",
                name:"impactNumber",
                disabled:this.state.is_sub_study === true
            },{
                label: "Notes",
                type: "textarea",
                name:"notes",
            },
        ]
    }
    get inputFieldsArrayRightCol(){
        return [
            {
                label: "Trial Category",
                type: "dropdown",
                placeholder: "Select Trial Category",
                name: "extTrialCategory",
                options: Object.keys(TRIAL_CATEGORY_2_STAGE_2_SUBMISSION_STATUS),
                onSelect: this.onTrailChange
            },{
                label: "Stage",
                type: "dropdown",
                placeholder: "Select Stage",
                name:"stage",
                options: this.state.stageList,
                onSelect: this.onStageChange
            },{
                label: "Submission Status",
                type: "dropdown",
                placeholder: "Select Status",
                name:"submissionStatus",
                options: this.state.statusList
            },
            {
                label: "[EXT] Country",
                type: "searchableDropdown",
                placeholder: "Select Country",
                name:"extCountry",
                options: this.state.countryList,
                onSelect: this.onSearchSelect
            },
            {
                label: "Country 1",
                placeholder: "Select Country",
                type: "searchableDropdown",
                name:"country1",
                options: this.state.countryList,
                onSelect: this.onSearchSelect
            },
            {
                label: "Country 2",
                type: "searchableDropdown",
                name:"country2",
                placeholder: "Select Country",
                options: this.state.countryList,
                onSelect: this.onSearchSelect
            },
            {
                label: "Country 3",
                type: "searchableDropdown",
                name:"country3",
                placeholder: "Select Country",
                options: this.state.countryList,
                onSelect: this.onSearchSelect
            },
            {
                label: "Country 4",
                type: "searchableDropdown",
                name:"country4",
                placeholder: "Select Country",
                options: this.state.countryList,
                onSelect: this.onSearchSelect
            },
            {
                label: "Study Title",
                type: "textarea",
                name:"studyTitle",
            },
            {
                label: "Shorthand Title",
                type: "text",
                name: "shorthandTitle",
                placeholder: "Enter Shorthand Title",
            },
            {
                label: "[EXT] Compound",
                type: "searchableDropdown",
                name: "extCompound",
                options: this.state.compoundList,
                onSelect: this.onSearchSelect
            },
            {
                label: "Additional Compounds",
                type: "multi-select",
                placeholder: "Select Additional Compounds",
                name: "addntlCompounds",
                options: this.state.compoundListMultiSelect,
            },
            {
                label: "Type of Material Provided",
                name: "type_of_material_provided",
                type: "dropdown",
                placeholder: "Select",
                options: [
                    "None",
                    "No drug",
                    "Comm pack with aux sticker",
                    "Investigational label"
                ]
            },{
                label: "Lilly Support ",
                type: "dropdown",
                placeholder: "Select Support",
                options: [
                    "Grants Only",
                    "Drug Only",
                    "Drug & Grant"
                ],
                name: "lilly_support",
            },
            {
                label: "[EXT] Other Indication",
                type: "text",
                placeholder: "Enter Other Indication",
                name:"extTumorIndication",
                helptext:"Indication of a concept"
    
            },
            {
                label: "[EXT] Tumors",
                type: "multi-select",
                placeholder: "Select Tumors",
                name: "extIndication",
                options: INDICATIONS,
                disabled: this.state.extTherapeuticArea !== "Oncology",
                helptext:"Oncology Only: Select tumor(s) related to submission"
            },
            {
                label: "[EXT] Other Tumors",
                type: "text",
                placeholder: "Enter Other Tumors",
                name: "extOtherIndication",
                helptext:"Oncology Only: Define the tumor"
            },
            {
                label: "[EXT] Line of Therapy",
                type: "dropdown",
                placeholder: "Select Line of Therapy",
                name:"extLineofTherapy",
                options: LINE_OF_THERAPY
            },
            {
                label: "Study Priority",
                type: "dropdown",
                placeholder: "Select Study Priority",
                name: "studyPriority",
                options: STUDY_PRIORITY
            },

            {
                label: "Is this a sub study",
                name:  "is_sub_study",
                type: "checkbox",
                helptext:"Check the field if this study is a child of a parent study"
            },
            {
                label: "Parent study submission number",
                name:  "parent_study_submission_number",
                type: "searchableDropdown",
                placeholder: "Select Submission Number",
                hidden: this.state.is_sub_study === false,
                options: this.state.parentSubmissionIdList,
                onSelect: this.onSearchSelect
            },
            {
                label: "Do not send automated emails",
                name:  "doNotSendAutomatedEmails",
                type: "checkbox",
            },
            {
                label: "Has Feasibility Assessment been sent to PD?",
                type: "checkbox",
                name:"is_feasibility_assessment_sent",
            },
            {
                label: "Last Modified By",
                type: "text",
                name:"last_modified_by",
                className:"createUpdateByfontsize",
                disabled: true
            },
            {
                label: "Created By",
                type: "text",
                name:"createdBy",
                className:"createUpdateByfontsize",
                disabled: true,
                
            },
        ]
    }
    componentDidMount() {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
            addntlCompounds:this.getAdtnlCompounds()
        })
        this.getCountryList();    
        this.getCompoundList(this.props.submissionDetails.concepts[0].country);
        this.getUserList();
        this.getInvestigatorList();
        this.getCompoundNewList();
        this.getParentSubmissionIdList();
    }
    getAdtnlCompounds=()=>{
   
        let {submissionDetails}=this.props;
        let data=[];
        if(submissionDetails.addntl_compounds_data?.length>0){
            let {addntl_compounds_data}=submissionDetails;
            data=addntl_compounds_data.map(obj=> {return {value: obj.id, label: obj.name}});
    
        }
        return data;
      }
    getCountryList = async () => {
        const res = await API.get("seed/country/data")
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id,countrycode:elm.countrycode}));
        this.setState({ countryList: resultArray}, this.setCountry);        
    }
    getParentSubmissionIdList = async () => {
        let study_number = this.state.parent_study_submission_number.toString(); 
        const res = await API.get(`submission/data`);
        let resultArray = [];
        if(res && res.data && res.data.length) {
            resultArray = res.data.map(elm => ({ 
                name: elm.id.toString(), 
                value: elm.id.toString(),
                trial_alias: elm.trial_alias,
                impact_no: elm.impact_no
            }));
        }
        this.setState({parentSubmissionIdList: resultArray}, () => {
            this.setState({parent_study_submission_number: {name: study_number, value: study_number}})
        });
    }

    searchCountry = (countryId) => {
        return this.state.countryList.find(country => country.value === countryId) || "";
    }

    setCountry = () => {
        const {concepts,country_1="", country_2="", country_3="", country_4=""} = this.props.submissionDetails
        this.setState({
            extCountry: this.searchCountry(concepts[0]?.country) || "",
            country1: this.searchCountry(country_1),
            country2: this.searchCountry(country_2),
            country3: this.searchCountry(country_3),
            country4: this.searchCountry(country_4),
        });        
    }
   
    formValidation = () => {
        let is_valid = true;
        let values=["Diagnostic Research","Translational Research"]
        if(this.state.extTherapeuticArea==="Oncology" && (!values.includes(this.state.requestType)) && (this.state.extIndication === null || !this.state.extIndication || this.state.extIndication?.length === 0) ) {
            is_valid = false;
            this.setState({
                error: {
                    extIndication: true
                }
            })
        }
        if(!this.state.interventional_study || this.state.interventional_study.toLowerCase() === "none"){
            is_valid = false;
            this.setState({
                error: {
                    interventional_study: true
                }
            })
        }
        
        if(this.state.is_sub_study && this.state.parent_study_submission_number && this.state.parent_study_submission_number.value === ''){
            is_valid = false;
            this.setState({
                error: {
                    parent_study_submission_number: true
                }
            })
        }
        return is_valid;
    }
    getErrorMessage=()=>{
        let fields=this.inputFieldsArray;
        let responseFields=[];
        
      for( const [key,value] of Object.entries(this.state.error))  {
    let x=fields.find(p=>p.name==key);
    if(x){responseFields.push(x.label)}
      }
        return responseFields
    }
    initialSubmissionData = () => {
        
        if(this.formValidation()){
        const newObject={};
        
        const obj = this.state.patchObject;
        const conceptFields = [
            "investigatorname",
            "country",
            "request_type",
            "secondary_request_type",
            "therapeutic_area",
            "compound_id",
            "concept_indication",
            "other_indication",
            
        ]
        const conceptObj = {}
        conceptFields.forEach(field => {
            if(field in obj){
                conceptObj[field] = obj[field];
                delete obj[field]
            }
        });
        
        //compound1
        
        if('concept_indication' in conceptObj || 'therapeutic_area' in conceptObj){
            conceptObj['indication'] = this.state.extTherapeuticArea==="Oncology"? this.state.extIndication.map(obj => obj.value).join(";") : null;
            delete conceptObj['concept_indication']
        }
         if('clinical_trail_phase' in obj)
            obj['clinical_trail_phase'] = this.state.extClinicalTrialPhase?.map(obj => obj.value).join(";") || "";
        if('assign_to' in obj)
            obj['assign_to'] = this.state.usersList.find(user => user.value === obj.assign_to).value || "";
        if('additional_cordinator' in obj)
            obj['additional_cordinator'] = this.state.usersList.find(user => user.value === obj.additional_cordinator).value || "";
        if(Object.keys(conceptObj).length !== 0)
            obj['concept'] = {...conceptObj, id: this.props.submissionDetails.concepts[0]?.id}
        if('stage' in obj)    {    
            obj['oldStageValue'] = this.state.oldStage;
            this.setState({oldStage:obj['stage']})
        }
        if('status' in obj)    {    
            obj['oldStatusValue'] = this.state.oldStatus;
            this.setState({oldStatus:obj['status']})
        }
        if('therapeutic_area' in conceptObj || 'country' in conceptObj || 'compound_id' in conceptObj || 'stage' in obj || 'request_type' in conceptObj ) 
        {
            if('request_type' in conceptObj)
           { this.state.request_type = conceptObj['request_type']==="Diagnostic Research"? "Concept-DGN-": ""||
           conceptObj['request_type']==="Translational Research"? "Concept-TR-": "" ||
           conceptObj['request_type']==="Pre-clinical"? "Concept-PRE-": "" ||conceptObj['request_type']==="IIT"? "Concept-IIT-": ""||conceptObj['request_type']==="ExIST"? "EXI-": "";
           } 
          else if(this.state.requestType) { this.state.request_type = this.state.requestType==="Diagnostic Research"? "Concept-DGN-": ""||
          this.state.requestType==="Translational Research"? "Concept-TR-": "" ||
          this.state.requestType==="Pre-clinical"? "Concept-PRE-": "" ||this.state.requestType==="IIT"? "Concept-IIT-": ""||this.state.requestType==="ExIST"? "Concept-EXI-": "";
          } 
        //  obj['sosl_help_field'] = this.state.extTherapeuticArea+" "+this.state.extCountry.countrycode+" "+this.state.extCompound.name+" "+this.state.request_type+this.state.extCompound.name+"-"+this.state.extCountry.countrycode+"-"+this.props.submissionDetails.id;
        //  this.setState({SOSLHelpField:obj['sosl_help_field']})
        }
        if('country' in conceptObj)
      {
        obj['country'] = this.state.extCountry;
        this.setState({extCountry:obj['country']})
      }

      if('trial_alias' in obj){
            obj['trial_alias'] = obj['trial_alias']?.trim();

            if(obj['trial_alias']?.length===0){
                obj['trial_alias'] = null;
            }
      }
        Object.keys(obj).forEach((key) => {
            if ((obj[key] === "" || obj[key] === null) && key!=='trial_alias' ) {

                newObject[key] = "";
              
            } 
            else{
                newObject[key] = obj[key];
            }
        });
        this.state.patchObject={};
            /*Logic for compund 1 and compund 2 filed update  */
            let { addntlCompounds } = this.state;
            if (addntlCompounds) {

                /*extracting the compund 1 or compund 2 from the list */
                let compound1 = addntlCompounds.find(p => p.field === "compound_1");
                let compound2 = addntlCompounds.find(p => p.field === "compound_2");

                /*Clearing the Compund 1 and Compund 2 field from the Additional Compund and updating */
                let newAdtnlCompunds = addntlCompounds.filter(p => (!p.field));
                if (newAdtnlCompunds && newAdtnlCompunds.length > 0) {
                    newObject.addntl_compounds = newAdtnlCompunds.map(p => p.value).join(",");
                }
                else {
                    newObject.addntl_compounds = null;
                }

                /*if compound 1 or compund 2 is removed from ui then set back to db  */
                if (!compound1 && this.state.compound1) {
                    newObject.compound_1 = null;
                }
                if (!compound2 && this.state.compound2) {
                    newObject.compound_2 = null;
                }
            }
          
        return newObject;
 }
 return {error:true}
 }
 
componentDidUpdate(previousProps, previousState) {
    let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
    if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
    this.setState({
        selectedRole: selectedRole,
        access: {
       view: true,
       create:  selectedRole.includes("administrator"),
       edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
       delete: selectedRole.includes("administrator"),
   },
    })
}
}
    getCompoundList = async (e) => {
        // const res = await API.get(`seed/compound/fulldata`)
        // const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
        const response = await API.get(`seed/getCompoundbyCountry/${e}`)
        const arr = response.data.compounds?.filter(elm => elm.therapeutic_area===this.state.extTherapeuticArea );
        const resultArray = arr?.map(elm => ({name: elm.name, value: elm.id}));
        this.setState(
            { 
                compoundList: resultArray,
                extCompound: resultArray?.find(compound => compound.value === this.props.submissionDetails.concepts[0]?.compound_id) || "",
            }
        );
    }
    getCompoundMergeInfo=(source,field)=>{
        let data=source.find(compound => compound.value === this.props.submissionDetails[field]) || null;
        if(data){
            return {value : data.value+"_"+field, label : data.name,field:field}
        }
        return null;
    }
    getCompoundNewList = async (e) => {
         const res = await API.get(`/seed/compound/add`)
         const resultArray = res.data.map(elm => ({
              name: elm.name.toLowerCase() === "other" || elm.name.toLowerCase() === "others"  ? `${elm.name} | ${elm.therapeutic_area}` : elm.name , value: elm.id
            }));
            let compoundListMultiSelect = resultArray?.map((obj) => {
                return { value : obj.value, label : obj.name }  
            });
           
            /*Merging existing Compund 1 and Compund 2 in the Additional Compund Field */
            let compound1 = this.getCompoundMergeInfo(resultArray, "compound_1");
            let compound2 = this.getCompoundMergeInfo(resultArray, "compound_2");
            let { addntlCompounds } = this.state;
            if (compound1) {
                addntlCompounds.push(compound1)
            }
            if (compound2) {
                addntlCompounds.push(compound2)
            }

        this.setState(
            { 
                // newCompoundList: resultArray || [],
                compoundListMultiSelect: compoundListMultiSelect || [],
                addntlCompounds:[...addntlCompounds||[]]
                // compound1: resultArray.find(compound => compound.value === this.props.submissionDetails.compound_1) || "",
                // compound2: resultArray.find(compound => compound.value === this.props.submissionDetails.compound_2) || "",
                // 

            }
        );
    }

    getUserList = async () => {
        const res = await API.get(`user/data`)
        const resultArray = res.data.map(elm => ({ 
            name: `${elm.first_name} ${elm.last_name} - ${elm.system_id}`, 
            value: elm.system_id,
            email:elm.email
        }));
        resultArray.unshift({name: " - None", value:""});
        this.setState({usersList: resultArray}, this.setUser);
    }
    getInvestigatorList = async () => {
        const res = await API.get(`submission/investigator/list`)
        //console.log(res)
        const resultArray = (res.data?.data || []).map(elm => ({
            name: `INVID- ${(elm.id+"").padStart(7,'0')} | ${(elm.investigator_last_name) ? (elm.investigator_last_name).slice(0,10) : ""}`,
            value: elm.id,
            investigatorName:`${elm.investigator_first_name !== null ? elm.investigator_first_name :""} ${elm.investigator_last_name !== null ? elm.investigator_last_name :""}`,
            investigator_first_name:elm.investigator_first_name,
            investigator_last_name:elm.investigator_last_name,
            email:elm.email,
            investigator_middle_name:elm.investigator_middle_initial
        }));
        //console.log("resultArray",resultArray)
        this.setState({investigatorList: resultArray,});
    }

    searchUser = (userId) => {
        return this.state.usersList.find(user => user.value === userId) || "";
    }

    setUser =async () => {
        const {assign_to="",additional_cordinator="",primary_msl="",additional_msl="",created_by=""} = this.props.submissionDetails;
        this.setState({
            assignedTo : this.searchUser(assign_to),
            additionalCoordinator : this.searchUser(additional_cordinator),
            extPrimaryMSL : this.searchUser(primary_msl),
            additionalMSL : this.searchUser(additional_msl),
            last_modified_by :this.props.submissionDetails.last_modified_by?.length ? await getUserInfo(this.props.submissionDetails.last_modified_by)+" "+dateTimeformat(this.props.submissionDetails.updatedAt):"",
            createdBy : this.props.submissionDetails.created_by?.length ? await getUserInfo(this.props.submissionDetails.created_by)+" "+ dateTimeformat(this.props.submissionDetails.createdAt):"",
        })
    }

    onStageChange = (e,eo) => {
        this.setState({
            stage: e,
            statusList: TRIAL_CATEGORY_2_STAGE_2_SUBMISSION_STATUS[this.state.extTrialCategory][e],
            submissionStatus: "None",
            patchObject:  {...this.state.patchObject, 
                [INITIAL_SUBMISSION['stage']]: e,
                [INITIAL_SUBMISSION['submissionStatus']]: "None"
            },
            hasChanged: true
        });
    }

    onTrailChange = (e,eo) => {
        this.setState({
            extTrialCategory: e,
            stageList:   Object.keys(TRIAL_CATEGORY_2_STAGE_2_SUBMISSION_STATUS[e]),
            
            statusList: ["None"],
            
            patchObject:  {...this.state.patchObject, 
                [INITIAL_SUBMISSION['extTrialCategory']]: e,
            },
            hasChanged: true
        });
    }
    onInvestigatorChange = (e, name) => {
       
            this.setState({
                [name]: e,
                patchObject:  {...this.state.patchObject, [INITIAL_SUBMISSION[name]]: e.value,[INITIAL_SUBMISSION["investigator"]]:e.investigatorName !== null ? e.investigatorName :"" ,"investigator_first_name":e.investigator_first_name !== null ? e.investigator_first_name : "","investigator_last_name":e.investigator_last_name !== null ? e.investigator_last_name : "","email":e.email !== null ? e.email : "","investigator_middle_name":e.investigator_middle_name !== null ? e.investigator_middle_name : ""},
                hasChanged: true
            });
       
    }
    onMSLChange = (e, name) => {
        if(name === "additionalMSL")
            this.setState({
                additionalMSL: e,
                additionalMSLEmail: e.email,
                patchObject:  {...this.state.patchObject, [INITIAL_SUBMISSION['additionalMSL']]: e.value || "", [INITIAL_SUBMISSION['additionalMSLEmail']]: e.email},
                hasChanged: true
            });
        else
            this.setState({
                extPrimaryMSL: e,
                extPrimaryMSLEmail: e.email,
                patchObject:  {...this.state.patchObject, [INITIAL_SUBMISSION['extPrimaryMSL']]: e.value || "", [INITIAL_SUBMISSION['extPrimaryMSLEmail']]: e.email},
                hasChanged: true
            });
    }

    onInputChange = (e) => {
        const { name, value} = e.target;
    this.setState({
        [name]: value,
        patchObject:  {...this.state.patchObject, [INITIAL_SUBMISSION[name]]: value},
        hasChanged: true
    });
    }
    handleValueChange=async(e, eo)=>{
        let fieldName=(eo.name || eo.target.name);
        let {submissionDetails}=this.props;
        let initialValue="";
        let process_change=false;
        let {messageView}=this.state;
        if(fieldName === "requestType")
        {         
            process_change=true;  
            initialValue=submissionDetails.concepts.length>0 ? submissionDetails.concepts[0].request_type : "";
        }       
        else if(fieldName === "secondary_request_type")
        {
            process_change=true;
            initialValue=submissionDetails.concepts.length>0 ? submissionDetails.concepts[0].secondary_request_type : "";
        }   
        if(process_change){
            messageView[fieldName]=initialValue!==e;
            this.setState({messageView});
        }
          
    }
    onSelectChange = async(e, eo) => {
        this.handleValueChange(e,eo);
        if((eo.name || eo.target.name) === "extTherapeuticArea")
        {
            this.setState({
            [eo.name || eo.target.name]: e,
            hasChanged : true,
            patchObject:  {...this.state.patchObject, [INITIAL_SUBMISSION[eo.name || eo.target.name]]:Array.isArray(e) ? e.map(obj => obj.label).join(";") : e},
            error: {
                [eo.name || eo.target.name]: false
            }
        })
           
          await  this.getCompoundList(this.state.extCountry.value)
        }
        else if((eo.name || eo.target.name) === "addntlCompounds"){
            this.setState({
                [eo.name || eo.target.name]: e,
                patchObject:  {...this.state.patchObject, [INITIAL_SUBMISSION[eo.name || eo.target.name]]:Array.isArray(e) ? e.map(obj => obj.value).join(",") : e},
                hasChanged: true,
                error: {
                    [eo.name || eo.target.name]: false
                }
            });   
        }
        else
       { this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [INITIAL_SUBMISSION[eo.name || eo.target.name]]:Array.isArray(e) ? e.map(obj => obj.label).join(";") : e},
            hasChanged: true,
            error: {
                [eo.name || eo.target.name]: false
            }
        });   
    }     
    }

    onSearchSelect = (e, name) => {
        
        if(name === 'parent_study_submission_number') {
            this.setState({
                [name]: e,
                trialAlias: e.trial_alias,
                impactNumber: e.impact_no,
                patchObject:  {...this.state.patchObject, [INITIAL_SUBMISSION[name]]: e.value, 
                    [INITIAL_SUBMISSION['trialAlias']]: e.trial_alias,
                    [INITIAL_SUBMISSION['impactNumber']]: e.impact_no},
                hasChanged: true
            }); 
        }
        else {
            this.setState({
                [name]: e,
                patchObject:  {...this.state.patchObject, [INITIAL_SUBMISSION[name]]: e.value},
                hasChanged: true
            });
        }
    }

    checkboxChange = (e) => {
        const { name, checked } = e.target;
        this.setState({
            [name]: checked,
            patchObject:  {...this.state.patchObject, [INITIAL_SUBMISSION[name]]: checked},
            hasChanged: true
        });
    }
    renderTootltipFields=(field)=>{
       
        let { messageView } = this.state;
        return <>
            <InputField

                {...field}
                disabled={field.disabled ? field.disabled : !this.state.access.edit}
                value={this.state[field.name]}
                error={this.state.error?.[field.name]}
                onSelect={field.onSelect || this.onSelectChange}
                onChange={field.type !== "checkbox" ? this.onInputChange : this.checkboxChange}
            />
            {messageView[field.name] && <div className="adtnl-message" style={{ position: 'absolute', left: '1.5rem', bottom: '-0.25rem' }}>
                {messageView.message}
            </div>}
        </>

    }
    isToolTipFields=(field)=>{
        return field.name==="requestType"||field.name==="secondary_request_type";
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                {this.isToolTipFields(field)&&this.renderTootltipFields(field)}
                {!this.isToolTipFields(field)&&<InputField 
                    {...field } 
                    hidden = {field.hidden}
                    disabled = {field.disabled ? field.disabled : !this.state.access.edit }
                    value={this.state[field.name]}
                     error={this.state.error?.[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={ field.type !== "checkbox"? this.onInputChange: this.checkboxChange }
                />}
            </Col>
            )
        });
    }
    renderInputFieldsNew = (inpFieldsArray) => {
        return inpFieldsArray.map(field => {
            // if (field.hidden){
            //     return false;
            // }
            return (
                <Col sm={12} key={field.label}>
                    {this.isToolTipFields(field)&&this.renderTootltipFields(field)}
                    {!this.isToolTipFields(field)&&<InputField 
                        {...field } 
                        hidden = {field.hidden}
                        disabled = {field.disabled ? field.disabled : !this.state.access.edit }
                        value={this.state[field.name]}
                         error={this.state.error?.[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                        onChange={ field.type !== "checkbox"? this.onInputChange: this.checkboxChange }
                    />}
                </Col>
            )
        });
    }

    submitForm = async () => {
        
        if(this.formValidation()){
        const obj = {...this.state.patchObject};
        const conceptFields = [
            "country",
            "request_type",
            "secondary_request_type",
            "therapeutic_area",
            "compound_id",
            "concept_indication",
            "other_indication",
            "last_modified_by"
        ]
        const conceptObj = {}
        conceptFields.forEach(field => {
            if(field in obj){
                conceptObj[field] = obj[field];
                delete obj[field]
            }
        });
        if('concept_indication' in conceptObj || 'therapeutic_area' in conceptObj){
            conceptObj['indication'] = this.state.extTherapeuticArea==="Oncology"? this.state.extIndication.map(obj => obj.value).join(";") :null;
            delete conceptObj['concept_indication']
        }
        if('clinical_trail_phase' in obj)
            obj['clinical_trail_phase'] = this.state.extClinicalTrialPhase?.map(obj => obj.value).join(";") || "";
        if('assign_to' in obj)
            obj['assign_to'] = this.state.usersList.find(user => user.value === obj.assign_to).value|| "";
        if('additional_cordinator' in obj)
            obj['additional_cordinator'] = this.state.usersList.find(user => user.value === obj.additional_cordinator).value|| "";
        if(Object.keys(conceptObj).length !== 0)
            obj['concept'] = {...conceptObj, id: this.props.submissionDetails.concepts[0]?.id}
        if('stage' in obj)    {    
            obj['oldStageValue'] = this.state.oldStage;
            this.setState({oldStage:obj['stage']})
        }
        if('status' in obj)    {    
            obj['oldStatusValue'] = this.state.oldStatus;
            this.setState({oldStatus:obj['status']})
        }
        if('therapeutic_area' in conceptObj || 'country' in conceptObj || 'compound' in conceptObj || 'stage' in obj || 'request_type' in conceptObj ) 
       {   if('request_type' in conceptObj)
       { this.state.request_type = conceptObj['request_type']==="Diagnostic Research"? "Concept-DGN-": ""||
       conceptObj['request_type']==="Translational Research"? "Concept-TR-": "" ||
       conceptObj['request_type']==="Pre-clinical"? "Concept-PRE": "" ||conceptObj['request_type']==="IIT"? "Concept-IIT-": ""||conceptObj['request_type']==="ExIST"? "Concept-EXI-": "";
       } 
       else if(this.state.requestType) { this.state.request_type = this.state.requestType==="Diagnostic Research"? "Concept-DGN-": ""||
       this.state.requestType==="Translational Research"? "Concept-TR-": "" ||
       this.state.requestType==="Pre-clinical"? "Concept-PRE-": "" ||this.state.requestType==="IIT"? "Concept-IIT-": ""||this.state.requestType==="ExIST"? "Concept-EXI-": "";
       }
      
        // obj['sosl_help_field'] = this.state.extTherapeuticArea+" "+this.state.extCountry.countrycode+" "+this.state.extCompound.name+" "+this.state.request_type+this.state.extCountry.countrycode+"-"+this.props.submissionDetails.id;
        // this.setState({SOSLHelpField:obj['sosl_help_field']})
       }
      if('country' in conceptObj)
      {
        obj['country'] = this.state.extCountry;
        this.setState({extCountry:obj['country']})
      }
        try {
            //("---patch---" , obj)
            const res = await API.patch("submission/update", obj);
            this.setState({
                successModal: true,
                patchObject: {"id": this.props.submissionDetails.id},
                hasChanged: false
            });
        }catch(err){
            this.setState({errorModal: true});
        }
    }
    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    render() {
        if(!this.props.submissionDetails.id)
            return <Loader />;

        return (
            <>
                <Row>
                    {/* {this.renderInputFields()} */}
                    {/* <div className="w-100 d-flex justify-content-center">
                        <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                            Save
                        </Button>
                    </div> */}
                    <Col sm={6}>
                        {this.renderInputFieldsNew(this.inputFieldsArrayLeftCol)}
                    </Col>
                    <Col sm={6}>
                        {this.renderInputFieldsNew(this.inputFieldsArrayRightCol)}
                    </Col>
                </Row>
                
                
                {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Updated successfully!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </>
        )
    }
}
