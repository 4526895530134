import axios from 'axios';
import React from 'react'
import { Col, Modal, Row} from 'react-bootstrap';
import InputField from '../../../common/input-fields/InputField';
import { showMessage, updateNotification } from '../../commonFunctions';
import FileUploader from '../../FileUploader';
import UserContext from '../../../../context/UserContext';
import SharepointConnect from '../../SharepointConnect';
import Button from '../../../common/Button';
export default class NewDocument extends React.Component{
    static contextType=UserContext;
    constructor(props) {
        super(props);
        this.state={
            externalurl:'',
            isIUDOperation:false,
            fileInfo:{
                selectedFileName:'',
                customName:'',
                file:null
            },
            sharePointFileInfo:{
                selectedFileName:'',
                customName:'',
                file:null
            }
        }
    }
    handleEvents=(option)=>{
        let {onClose}=this.props;
        switch(option){
            case 'CLOSE':if(onClose){onClose('CLOSE');}break;
            case 'REFRESH':if(onClose){onClose('REFRESH');}break;
            case 'SAVE':this.initiateFileUpload();break;
        }
    }
    initiateSharepointUpload=async()=>{
      
         let {sharePointFileInfo}=this.state;
         let {reviewers_detail_id,reviewerId,submission_id,externalurl,review_type}=this.props;
         if(sharePointFileInfo&&sharePointFileInfo.file){             
             let fileData=[];
             fileData.push({
                fileInfo:sharePointFileInfo ,                     
                submissionId:submission_id,
                review_type:review_type,
                review_header_id:reviewerId,
                reviewers_detail_id:reviewers_detail_id
            });
            
             if (fileData && fileData.length > 0) {
                 let asyncFiles = [];
                 let sc=new SharepointConnect();
                 let stateInfo={};
                 await sc.loadConfigInformation().then((data)=>{    
                     stateInfo=data;
                 });
                 let item=fileData[0];
                 let newState=Object.assign(stateInfo,item);
                 let sc_uploader=new SharepointConnect(newState);
                 let response= await sc_uploader.uploadFile(item.fileInfo.file);
                //  let records = [];
                //  await Promise.all(asyncFiles)
                //  .then((d) => {
                    
                //      records=[...d];
                //  });
             }
         }
        
     }
    initiateFileUpload=async ()=>{
       this.setState({isIUDOperation:true});
      
       let {reviewers_detail_id,reviewerId,submission_id,externalurl}=this.props;
        const formData = new FormData();
        let {fileInfo,sharePointFileInfo}=this.state;
        if((!fileInfo || !fileInfo.file) && (!sharePointFileInfo||!sharePointFileInfo.file) ){
            showMessage({
                text: "Please select any file",
                icon: "error",
                button: "Ok",

            }, (e) => {
               
            });
            return false;
        }
        let pass=false;
        if(sharePointFileInfo&&sharePointFileInfo.file){
            await this.initiateSharepointUpload();
            pass=true;
        }
        if (fileInfo && fileInfo.file &&externalurl) {
           
            formData.append(
                'file',
                fileInfo.file,
                fileInfo.customName
            );
            formData.append(
                'created_by',
                this.context.userSystemId
            );
            formData.append(
                'updated_by',
                this.context.userSystemId
            );
            formData.append(
                'reviewers_detail_id',
                reviewers_detail_id
            );
            formData.append(
                'review_header_id',
                reviewerId
            );
            
            try {
                let fileUrl = `${externalurl}/fileupload/Reviewer/${submission_id}`;
                let res = await axios.post(fileUrl, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    },
                });
                this.setState({isIUDOperation:false});
                showMessage({
                    text: "Saved Successfully",
                    icon: "success",
                    button: "Ok",
    
                }, (e) => {
                   this.handleEvents("REFRESH");
                });
                pass=true;

            } catch (e) {
                this.setState({isIUDOperation:false});
                showMessage({
                    text: "Failed!",
                    icon: "error",
                    button: "Ok",
    
                }, (e) => {
                  
                });
                pass=false;
            }
        }
        if(pass){
           
            if(sharePointFileInfo&&sharePointFileInfo.file&&!(fileInfo && fileInfo.file &&externalurl)){
                this.setState({isIUDOperation:false});
                showMessage({
                    text: "Saved Successfully",
                    icon: "success",
                    button: "Ok",
    
                }, (e) => {
                   this.handleEvents("REFRESH");
                });
            }
            updateNotification(this.context.userSystemId,this.props.reviewerId);
        }
        return pass;
    }
    
    render(){
        let {fileInfo,isIUDOperation,sharePointFileInfo}=this.state;
        let loaderConfig={};
        if(isIUDOperation){
             loaderConfig={disabled:"disabled"};
        }
        return <Modal
        show={true}
            size="md"
            className="modal-mdx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{ border: 0 }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="font-weight-bold"> Add a Document</h5>
                    <div style={{ fontSize: '1rem' }}>
                        <span></span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="new-flow-modal">
                <Row>
                <Col sm={12}>
                    <InputField
                           label= "Name "
                           type= "text"
                           name= "customName"
                           value={
                            (fileInfo.customName||sharePointFileInfo.customName)?[sharePointFileInfo.customName]:""}
                           placeholder="File Name"
                        />
                </Col>
               
               
                    {/* <FileUploader fileInfo={fileInfo} onChange={e=>{
                        let activeFile= e.target.files[0];    
                        /*for blob url var path = (window.URL || window.webkitURL).createObjectURL(activeFile);   
                        let path=(e.target.value||'').replace("fakepath","...");            
                        this.setState({fileInfo:{selectedFileName:path,customName:activeFile.name,file:activeFile}},()=>{                         
                        });
                    }}></FileUploader> */}
                  
                    <Col sm={12} md={12}>
                    <FileUploader placeholder="Sharepoint File" fileInfo={sharePointFileInfo} onChange={e=>{
                        let activeFile= e.target.files[0];    
                        /*for blob url var path = (window.URL || window.webkitURL).createObjectURL(activeFile);   */
                        let path=(e.target.value||'').replace("fakepath","...");            
                        this.setState({sharePointFileInfo:{selectedFileName:path,customName:activeFile.name,file:activeFile}},()=>{                         
                        });
                    }}></FileUploader>
                   
                </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                    {isIUDOperation&&<div className="d-flex align-items-center justify-content-center p-2">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>}
                    <div className="d-flex align-items-center justify-content-center p-2 w-100">
                        <Button {...loaderConfig} onClick={e=>this.handleEvents('CLOSE')} className="mr-2 p-2" style="danger w-100px">Cancel</Button>
                        <Button {...loaderConfig} onClick={e=>this.handleEvents('SAVE')} className="d-flex align-items-center justify-content-center p-2" style="success w-100px"><i className="bi bi-plus" ></i><span>ADD</span></Button>
                    </div>
      </Modal.Footer>
        </Modal>
    }
}