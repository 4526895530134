import React, { Component ,useState,useEffect} from 'react'
import {  Col, Container, Form, Modal, Row,Button as ButtonEx, Table } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { dateChange, dateStringformat, dateTimeformat, getUserInfo } from '../utility/helperFunctions';
import { yyyymmddFormat } from './common/dateFormat';
import InputField from './common/input-fields/InputField';
import Loader from './common/loader/Loader';
import Button from './common/Button'
import LoaderEx from './common/loader/LoaderEx';
import BootstrapModal from './common/modal/BootstrapModal';
import API from '../api/apis';
import UserContext from '../context/UserContext';
import FileUploader from './ToReviewDetails/FileUploader';
import { isCoordinator, showMessage } from './ToReviewDetails/commonFunctions';
import axios from 'axios';
import swal from 'sweetalert';
import ToolTipEx from './common/ToolTipEx';
import { DEFAULT } from '../TooltipInfo';
const CustomCreatable=({field,options})=>{
    const [value,setValue]=useState([]);
    const [source,setOptions]=useState([]);
    useEffect(()=>{
        if(options){
            let sourceData= (options||[]).map((item,index)=>{
                let data={};
                data.id=index;
                data.value=item;
                return data;
            });
            setOptions(sourceData);
        }
    },[options]);
    const handleRemoveItem=(item)=>{
       source.splice(source.indexOf(item),1);
       let response=source.map(p=>p.value);
       if(field.onChange){
        field.onChange(field.name,response);
    }
    }
    return (<Col sm={12} key={field.label}>            
        <InputField
            {...field}
            type="text"
            value={value}            
            onChange={(e)=>{
                let val=(e.target.value||'').replace(/,/g, "");
                setValue(val);
            }}
            onKeyDown={event=>{
                if (!value) return;            
                switch (event.key) {
                  case 'Enter':
                  case 'Tab':
                    let pinValue=source;
                    
                    // let maxId=0;
                    // if(pinValue&&pinValue.length>0){
                    //     maxId= (pinValue||[]).reduce((p, c) => p.id > c.id ? p : c);
                    // }              
                    let newSource=[...(pinValue||[]),{value:event.target.value}];     
                    //setOptions(newSource);
                    let response=newSource.map(p=>p.value);
                    setValue('');
                    if(field.onChange){
                        field.onChange(field.name,response);
                    }
                    event.preventDefault();
                }

            }}
        />
        <div className='d-flex flex-wrap ' style={{marginLeft:'2%',width:'95%'}}> 
               {
                (source).map((p,index)=>{
                    return <div onClick={e=>handleRemoveItem(p)} key={`${p.id}_${index}`} className='custom-creatable-pill d-flex align-items-center rounded-pill pl-3 pr-3 pt-2 pb-2 mb-2 mr-2'>
                                <span className='flex-grow-1'>{p.value}</span>
                        <span style={{fontSize:'0.8rem'}} className="material-icons ml-2">
                        cancel
                        </span>
                    </div>;
                })
               }

        </div>
    </Col>);
}

class NewPublicationEx2 extends Component {
    static contextType=UserContext
    state = {
        title: "",
        submissionId:`Submission- ${this.props.submission_id}`,
        isLoader:false,
        review:"Not Started",
        date_of_publication:'',
        planned_date_of_submission:"",
        date_publication_received_by_lilly:"",
        date_lilly_review_needed_by:"",
        uploadedFileList:[],
        s3FileInfo:{}
    }
    get inputFieldsArray() {
        return [
            {
                label: "Document Type *",
                type: "dropdown",
                name: "format",
                options: ["Manuscript","Final Report","Interim Report", "Presentation", "Poster", "Abstract", "Other"]
            },
            {
                label: "Title of Publication *",
                type: "text",
                placeholder: "Enter Title of Publication",
                name: "title"
            },           
            {
                label: "Primary Author *",
                type: "text",
                placeholder: "Enter Primary Author",
                name: "primary_author"

            },
            {
                label: "Target Journal / Congress / Venue",
                type: "text",
                placeholder: "Enter Target Journal / Congress / Venue",
                name: "venue"

            },
            {
                label: "Publication Short Name",
                type: "text",
                placeholder: "Publication Short Name",
                name: "publication_short_name"

            },
            // {
            //     label: "Venue",
            //     type: "text",
            //     placeholder: "Enter Venue",
            //     name: "venue"
            // },
            {
                label: "Date Publication Received by Lilly",
                type: "date",
                placeholder: "Select Date",
                name: "date_publication_received_by_lilly",

            },
            {
                label: "Planned Date of Submission",
                type: "date",
                placeholder: "Select Date",
                name: "planned_date_of_submission",

            },           
            {
                label: "Date Lilly Review Needed By",
                type: "date",
                placeholder: "Select Date",
                name: "date_lilly_review_needed_by",

            },
            {
                label: "Date of Publication",
                type: "date",
                placeholder: "Select Date of Publication",
                name: "date_of_publication",

            },
            {
                label: "Link to Publication / DOI",
                type: "text",
                placeholder: "Enter Link to Publication / DOI",
                name: "link_to_publication",

            },
            {
                label: "Review Status",
                type: "dropdown",
                placeholder: "Not started",
                name: "review",
                options: ["None","Review Complete","Under Review","Not started"],
                // disabled:true
            },

            {
                label: "Submitter Name",
                type: "text",
                placeholder: "",
                name: "submitter_name"
            },
            {
                label: "Submitter Email",
                type: "text",
                placeholder: "Enter Submitter Email",
                name: "submitter_email"
            },
            {
                label: "MeSH Keywords",
                type: "custom_creatable",
                placeholder: "Enter Keywords and press Enter Key or Tab key",
                name: "mesh_keywords",
                onChange:this.handleOnchange
               
            },
            {
                label: "Comments",
                type: "textarea",
                placeholder: "Enter Comments",
                name: "comments",
                rows: "1",

            }
        ];
    }
    get auditInputs(){
        return [
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                readonly:true
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                readonly:true
            }
        ]
    }
    async componentDidMount() {
       this.getPublicationById();
    }
    renderCustomCreatable=(field)=>{
        let source=this.state[field.name];
        let options=null;
        if(source){
            options=(source||'').split(',');
        }
        return (<CustomCreatable field={field} options={options}></CustomCreatable>);
    }
    onInputChange = (e,eo) => {
       
        if (eo&&eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                hasChanged: true
            });
        }
        else 
        { 
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }
    handleOnchange=(name, value)=>{ 
        this.setState({
            hasChanged: true,
            [name]:(value||[]).join(","),
        });
    }
    onSelectChange = (e, eo) => {
       
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
        const mandatoryFields=['title','format','primary_author'];
        const errors = {}
        mandatoryFields.forEach((key) => {
            if (!this.state[key]||this.state[key] === "" ) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors }
            });
        });

        return is_valid;
    }
    getFilesList = async (submission_id, id) => {
       
        try {
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            let files=res.data?.filter(file => file.type === `Publication_${id}`&&!file.is_deleted);
            return files;
        } catch (e) {
           
        }
    }
    getPublicationById = async () => {
        const d=await this.getExternalUrl();
        let { publication_id ,submission_id} = this.props;
        if(publication_id){
            this.setState({
                showLoader: true
            })

            let manuScriptMapping="Manuscript for Journal";
            const res = await API.get(`publication/getbyid/${publication_id}`);
            let S3Files= await this.getFilesList(submission_id,publication_id);
            let {format,title,primary_author,target_journal_congress, publication_short_name,venue,date_publication_received_by_lilly,
                planned_date_of_submission,date_of_publication,review,submitter_name,submitter_email,mesh_keywords,comments,lilly_feedback,date_lilly_review_needed_by, link_to_publication}=res.data;

            format=(format||'').toString().toLowerCase()=== manuScriptMapping.toLocaleLowerCase()?"Manuscript":format; 
            lilly_feedback = res.data.lilly_feedback !== null ? res.data.lilly_feedback.replace(/<[^>]+>/g, '') : "";
            date_publication_received_by_lilly= res.data.date_publication_received_by_lilly != "" && res.data.date_publication_received_by_lilly != null ?
            dateStringformat(res.data.date_publication_received_by_lilly) : null;
            date_lilly_review_needed_by= res.data.date_lilly_review_needed_by != "" && res.data.date_lilly_review_needed_by != null ?
            dateStringformat(res.data.date_lilly_review_needed_by) : null;
            date_of_publication = res.data.date_of_publication != "" && res.data.date_of_publication != null ?
                dateStringformat(res.data.date_of_publication) : null;
                planned_date_of_submission = res.data.planned_date_of_submission != "" && res.data.planned_date_of_submission != null ?
                dateStringformat(res.data.planned_date_of_submission) : null;
            let createdby = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
            let updatedby = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt) : res.data.createdby;
            let source={
                format,title,primary_author,target_journal_congress, publication_short_name,venue,date_publication_received_by_lilly,
                planned_date_of_submission,date_of_publication,review,submitter_name,submitter_email,mesh_keywords,comments,createdby,updatedby, link_to_publication,
                date_lilly_review_needed_by
            }
           
            this.setState({
                ...source,
                S3Files:S3Files||[],
                originalSource:JSON.parse(JSON.stringify(source)),
                showLoader: false
            });
        }
        else{
           let userName= await getUserInfo(this.context.userSystemId) ;
           this.setState({submitter_name:userName});
        }
       
    }
    getFormValues=()=>{
        let {publication_id,submission_id}=this.props;
        let {originalSource}=this.state;
        let {format,title,primary_author,target_journal_congress, publication_short_name,venue,date_publication_received_by_lilly,
            planned_date_of_submission,date_of_publication,review,submitter_name,submitter_email,mesh_keywords,comments,date_lilly_review_needed_by,link_to_publication
        }=this.state;
       
        let response={
            format,title,primary_author,target_journal_congress, publication_short_name,venue,date_publication_received_by_lilly,
            planned_date_of_submission,date_of_publication,review,submitter_name,submitter_email,mesh_keywords,comments,date_lilly_review_needed_by,link_to_publication
        };

        
        response["submission_id"]=submission_id;
        response["created_by"]=this.context.userSystemId;
        response["date_of_publication"] =date_of_publication!= "" && date_of_publication != null ?
        dateChange(date_of_publication) : null;
        response["planned_date_of_submission"] = planned_date_of_submission != "" && planned_date_of_submission != null ?
        dateChange(planned_date_of_submission) : null;
        response["date_publication_received_by_lilly"]= date_publication_received_by_lilly != "" && date_publication_received_by_lilly != null ?
        dateChange(date_publication_received_by_lilly) : null;
        response["date_lilly_review_needed_by"]= date_lilly_review_needed_by != "" && date_lilly_review_needed_by != null ?
        dateChange(date_lilly_review_needed_by) : null;
        if(publication_id){
            delete response.updatedby;
            delete response.createdby;
            delete response.created_by
            response.id         =publication_id;
            response.updated_by =this.context.userSystemId;
            if(originalSource){
                //ensuring only required data                 
                for (const key in originalSource) {
                        if(originalSource[key]===response[key]){
                            delete response[key];
                        }
                  }
            }

        }


        return response;
    }
    handleSave=async()=>{
        this.setState({showLoader:true});
        let modal=null;
        try{
            let {publication_id}=this.props;
            let inputs=this.getFormValues();
            let api_part="publication/add";
            let method="post";
            if(publication_id){           
                api_part="publication/update";
                method="put";
            }
            const res = await API[method](api_part, inputs);           
            if(res&&res.data&&(res.data.id||Array.isArray(res.data))){
                await this.uploadFiles(res.data.id||publication_id);
                modal="SUCCESS";
            }
            else{
                modal="ERROR";
            }
           
        }
        catch(exp){

        }
        finally{
            this.setState({modal,showLoader:false});
        }
        
        
    }
    async getExternalUrl() {
        let response = '';
        try {
            response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
            externalurl: (response && response.data) ? response.data.exturl : "http://localhost:1337",
        })
    }
    uploadFiles=async(id)=>{
        
        let {uploadedFileList}=this.state;
        let {submission_id,publication_id}=this.props;
        if(uploadedFileList&&uploadedFileList.length>0){
            let formData = new FormData();
            uploadedFileList.forEach((item,index)=>{
                formData.append(
                    'file',
                    item.fileInfo,
                    item.fileInfo.name
                );
            })
            
            try {
                this.setState({
                    showLoader: true
                })
                
                let fileUrl = `${this.state.externalurl}/fileupload/multiple/Publication/${submission_id}/${id||publication_id}`;
                let res = await axios.post(fileUrl, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    },
                });
               
            } catch (e) {
              
                console.log(e);
            }
            this.setState({
                showLoader: false
            })
        }
        
    }
    onSave = () => {
        if (this.formValidation()) 
        { 
            this.handleSave();
         }

    }   
    renderRadioGroup=(field)=>{
        let val=this.state[field.name];
        let error=this.state.error;
        return <Col sm={12} key={field.label}>
            <div className='d-flex flex-column mb-3' style={{marginLeft:'2%',width:'95%'}}>
                <label>{field.label}</label>
                <div>
                {field.options.map((item,index)=>{
                    return <Form.Check style={{fontWeight:'500'}} onChange={this.onInputChange} value={item} checked={(val===item)} key={`inline-radio-${field.name}-${index}`} inline  label={item} name={field.name} type={"radio"} id={`inline-radio-${field.name}-${index}`} />
                })}
                </div>
                {error && error[field.name]&&<p className="text-danger mb-0">This field is required.</p>}
             </div>
    </Col>
    }
    handleRemoveFile=(item)=>{
        let {uploadedFileList}=this.state;
        uploadedFileList.splice(uploadedFileList.indexOf(item),1);
       this.setState({uploadedFileList:[...uploadedFileList]});
    }
    handleDownload = async (item) => {
        try {
            this.setState({
                showLoader: true
            })
            let fileName=(item.key||'').split('/').pop();
            let { submission_id,publication_id } = this.props;
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Publication/${submission_id}/${publication_id}?name=${encodeURIComponent(fileName)}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response.ok) {
                        return response.blob();
                    }
                    else {                      
                        showMessage({                  
                            text: 'Error Encountered.Please try again.',
                            icon: "error",
                            button: "Ok",                          
                          },()=>{});
                        
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    showMessage({                  
                        text: "File Downloaded Successfully!",
                        icon: "success",
                        button: "Ok",
                      
                      },()=>{});
                });
        } catch (e) {          
        }
        finally{
            this.setState({
                showLoader: false
            });
        }
    }
    handleDelete = async (item) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({
                            showLoader: true
                        })
                        let fileName=(item.key||'').split('/').pop();
                        let { submission_id,publication_id } = this.props;
                        const res = await API.post(`/user/multiple/delete/Publication/${submission_id}/${publication_id}/${encodeURIComponent(fileName)}`, { updated_by: this.context.userSystemId });

                        showMessage({                  
                            text: "File Deleted!",
                            icon: "success",
                            button: "Ok",
                          
                          },()=>{});
                    }
                    catch (err) {
                        showMessage({                  
                            text: "Failed!",
                            icon: "error",
                            button: "Ok",
                          
                          },()=>{});
                    }
                    finally{
                        this.getPublicationById();
                    }

                }
            });


    }
    renderFileUploadArea=()=>{
        let {s3FileInfo,uploadedFileList,S3Files}=this.state;
        return <Col sm={12} >
        
                    <div className='d-flex flex-column mt-4' style={{marginLeft:'2%'}}>
                    {isCoordinator(this.context)&&<><h5 className='font-weight-bold'>Upload File</h5>
                        <span style={{fontWeight:500}}>Select a Document from your Computer</span>
                        <div className='d-flex' >
                        <div style={{width:'50%'}}> <FileUploader style={{marginLeft:0}} placeholder="Select File" fileInfo={s3FileInfo} onChange={e=>{
                        let activeFile= e.target.files[0];    
                        /*for blob url var path = (window.URL || window.webkitURL).createObjectURL(activeFile);   */
                        let path=(e.target.value||'').replace("fakepath","...");            
                        this.setState({s3FileInfo:{selectedFileName:path,file:activeFile}},()=>{                         
                        });
                    }}></FileUploader></div>
                 <ButtonEx size="sm"
                        style={{width:'75px', height:'37px'}}
                        className="d-flex align-items-center ml-3"
                        onClick={e=>{
                            if(s3FileInfo&&s3FileInfo.file){
                                let maxId=0;
                                 if((uploadedFileList||[]).length>0){
                                    let maxIdObj= (uploadedFileList||[]).reduce((p, c) => p.id > c.id ? p : c);
                                    maxId=maxIdObj.id+1;
                                 }                                
                                let isFileExists=(uploadedFileList||[]).find(p=>p.fileInfo.name.toLowerCase()===s3FileInfo.file.name.toLowerCase());
                                let isS3FileExist=(S3Files||[]).find(p=>(p.key||'').split('/').pop().toLowerCase()===s3FileInfo.file.name.toLowerCase());
                                if(!isFileExists&&!isS3FileExist){
                                    uploadedFileList.push({id:maxId,fileInfo:s3FileInfo.file});
                                    this.setState({uploadedFileList:[...uploadedFileList],hasChanged:true,s3FileInfo:{selectedFileName:'',file:null}});
                                }
                                else{
                                    showMessage({                  
                                        text: "File is already exist with the same name!",
                                        icon: "warning",
                                        button: "Ok",
                                      
                                      },()=>{});
                                }
                              
                            }
                          
                        }}
                        variant="dark">
                        <span className="material-icons mr-1 m-0">add </span>
                        <span style={{ fontSize: ' 0.75rem' }}>ADD</span>
                    </ButtonEx>
                        </div></>}
                    { ((uploadedFileList&&uploadedFileList.length>0) || (S3Files && S3Files.length>0)) &&<div style={{ maxHeight: '400px', overflowY: 'auto', fontSize: '.75rem' }}><Table responsive="sm">
                    <thead style={{ position: 'sticky', top: -2, backgroundColor: '#FFF', zIndex: 1, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <tr>
                            <th className='bg-white'>Uploaded Document</th>
                            <th className="text-right bg-white">Actions</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {
                             S3Files&&S3Files.map((item, index) => {
                                return <tr key={item.id}>
                                    <td>{(item.key||'').split('/').pop() ?? "File Name Missing"}</td>
                                    <td className="text-right">

                                        <div className='d-flex justify-content-end align-items-center'>
                                        <ToolTipEx tooltip={DEFAULT.DOWNLOAD}><span class="material-icons mr-2" style={{cursor:'pointer'}} onClick={e => {
                                              this.handleDownload(item);
                                            }} >
                                                cloud_download
                                            </span></ToolTipEx>
                                            {isCoordinator(this.context)&&<ToolTipEx tooltip={DEFAULT.DELETE}><span className="material-icons" onClick={e => {
                                               this.handleDelete(item);

                                            }} style={{ cursor: 'pointer' }}>
                                                close
                                            </span></ToolTipEx>}
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                        {
                            
                            uploadedFileList&&uploadedFileList.map((item, index) => {
                                return <tr key={item.id}>
                                    <td>{item.fileInfo.name ?? ""}</td>
                                    <td className="text-right">
                                        <div className='d-flex justify-content-end align-items-center'>
                                        <ToolTipEx tooltip={DEFAULT.DELETE}><span className="material-icons" onClick={e => {
                                                this.handleRemoveFile(item);

                                            }} style={{ cursor: 'pointer' }}>
                                                close
                                            </span></ToolTipEx>
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
                </div>}
                </div>
                    </Col>
       
    }
    renderInputFields = () => {
        let {publication_id}=this.props;
        let audit_fields=[];
        let fields=  this.inputFieldsArray.map(field => {
            if(field.name==="format")
                return this.renderRadioGroup(field);
            if(field.type==="custom_creatable")
            return this.renderCustomCreatable(field);
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={12} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
        if(publication_id){
            audit_fields= this.auditInputs.map(field => {
           
                return (
                    <Col sm={12} key={field.label}>
                        <InputField
                            {...field}
                            value={this.state[field.name]}
                        />
                    </Col>
                )
            });
        }
        return [...fields,...audit_fields];
    }
    changeShowState=()=>{
        let {modal}=this.state;
        if(modal==="SUCCESS"){
            this.onHide();
        }
    }
    onHide=()=>{
        let {handleEvents}=this.props;
        if(handleEvents){
            handleEvents({option:'ONCLOSE'});
        }
    }
    getSuccessmessage=()=>{
        let {publication_id}=this.props;
        if(publication_id) return "Publication has been updated!";
        return "Publication has been created!";
    }
    render() {
        const { handleModalShowHide, showHideModal, handlePublicationSave, viewEditFlag, handlePublicationUpdate ,publication_id } = this.props;
        let {hasChanged,modal,showLoader}=this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg" >
                {this.state.isLoader=== true ?
        <Loader /> :   <div> <Modal.Header className='border-0' >
                        <Modal.Title className="w-100 d-flex justify-content-start">
                            {publication_id ?
                                `Edit Publication ` : "Add a Publication"}
                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" onClick={e => {
                                this.onHide();
                            }} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                    </Modal.Header>
                     <Modal.Body className="new-flow-modal">
                     {showLoader&& <LoaderEx />}
                     {
                            modal&& <BootstrapModal 
                            show={true} 
                            header={modal==="SUCCESS"?"Success":"Error"}
                            body={modal==="SUCCESS"?<>{this.getSuccessmessage()}</>:<><div>An error occured! Please try again later!</div></>}
                            changeShowState={this.changeShowState}
                        />
                        }
                   <Row>
                            {this.renderInputFields()}
                            {this.renderFileUploadArea()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button disabled={showLoader} style="danger w-100px" className="p-2" onClick={() =>this.onHide()}>
                            Cancel
                        </Button>
                        {isCoordinator(this.context)&& <Button disabled={(!hasChanged && publication_id) || showLoader} style="success w-100px" className="p-2 d-flex align-items-center justify-content-center" onClick={e => {
                             this.onSave();
                        }}>
                           {!publication_id&& <><i className="bi bi-plus" ></i>  <span>Add</span></>}
                           {publication_id && <><span className="material-icons mr-1" style={{fontSize:'19px'}}>save</span><span>SAVE</span></>}
                        </Button>}

                    </Modal.Footer>  </div>}
                </Modal>
            </div>
        )
    }
}


export default NewPublicationEx2;