
import { Alert, Badge, Button,SplitButton,DropdownButton } from 'react-bootstrap';

import ComponentViewer from './ComponentViewer';

import { useState,useEffect } from 'react';
import { forms, SERVICE_DOMAIN } from './Constants';
import { getSecurityHeader, getServiceDomain } from './Common';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';


export default()=> {
  const[activeForm,setActiveForm]=useState(forms.DASHBOARD1)
  const [isServiceAvailable,setIsServiceAvailable]=useState(null);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [show, setShow] = useState(true);
  useEffect(()=>{
    //doHealthCheck();
    // const interval = setInterval(() => {
    //   doHealthCheck();
    // }, 5000);
   
    // return () => {
    //   clearInterval(interval);
    // };
  },[])
  const handleOnChange=(form)=>{
    setActiveForm(form);
  }
  

  const doHealthCheck=async()=>{
    let SERVICE_DOMAIN=getServiceDomain();
    let data= await fetch(`${SERVICE_DOMAIN}/common/health-check`,getSecurityHeader()).then((response)=>{
      return response.json();
    });
    setIsServiceAvailable(data.status||false);
  }
  return (
    <div className="App">
      <header className="App-header" >
        
        <div className='row mb-3'>
          <div className='col-md-12' >
            <h3>
              <SplitButton
                variant={"success"}
                title={"Dashboard"}
                
                id={`split-button-basic-1`}
              >
                 {
                    Object.entries(forms).map(([key,value])=>{
                      return  <DropdownItem className='p-2' eventKey={key} key={key} onClick={e=>setActiveForm(value)}>
                      <i>{value.title}</i>
                      </DropdownItem>
                    })
                  }   
              </SplitButton>              
              : {activeForm.title}</h3>

          </div>
        </div>
        <ComponentViewer component={activeForm.form}></ComponentViewer>
      </header>
    </div>
  );
}


