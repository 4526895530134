import React from 'react'
import { Table } from 'react-bootstrap';
import API from '../../../../api/apis'
import { dateformat, timeformat } from '../../../../utility/helperFunctions';
import { yyyymmddFormat } from '../../../common/dateFormat';
import UserContext from '../../../../context/UserContext';
import { isCoordinator, showMessage } from '../../commonFunctions';
import swal from 'sweetalert';
import NewDocument from './NewDocument';
import { DEFAULT } from '../../../../TooltipInfo';
import ToolTipEx from '../../../common/ToolTipEx';
import SharepointConnect from '../../SharepointConnect';
import DTAButton from '../../../common/Button/DTAButton';
import { iirDateFormat } from '../../../../utility/dateHelper';
export default class Documents extends React.Component{
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state={
            showLoader:false,
            source:[],
            sharepointFiles:[],
            is_coordinator:false
        }
        this.review_status_column="review_data";
    }
    columns = [
        {
            dataField: 'id',
            text: 'id',
            sort: true,
            hidden:true
        },
        {
            dataField: 'key',
            text: 'Document',
            sort: true,
            formatter: (cell, row, rowIndex) => {         
                let customText="";
                if(row.s3){
                    customText=<small><strong>{row.type?.split('_')[0]??""}</strong></small>;
                }       
                return <div className="d-flex flex-column">{row.key.split('/').pop()}{customText}</div>;
            }
        },
        {
            dataField: 'createdAt',
            text: 'Added Date',
            sort: true,
            formatter: (cell, row, rowIndex) => {                
                return iirDateFormat(row.createdAt);
            }
        },
        {
            dataField: 'createdAt',
            text: 'Added Time',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return timeformat(row.createdAt);
            }
        },
        {
            dataField: 'reviewer_name',
            text: 'User',
            sort: true
            
        },
        {
            dataField: 'id',
            text: 'Action',
            sort: true,
            align:'center',
            formatter: (cell, row, rowIndex) => {
                return this.renderActionArea(row);
            }
            
        },
        ];
        componentDidMount(){
            let { is_coordinator } = this.state;
            is_coordinator = isCoordinator(this.context);
            this.getExternalUrl();
            this.setState({ is_coordinator }, () => {
                this.getDocumentList();
            })
           
        }
    Loader=()=>{
        return  <div className="d-flex align-items-center justify-content-center p-2 w-100 h-100 position-absolute" style={{left:0,top:0}}>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>
    }
    getReviewerList=async()=>{
        try{
            this.setState({showLoader:true});
            //const res = await API.get(`reviewConcept/concept/reviews/list/${this.props.reviewerId||''}`);     
            const res = await API.post(`reviewsTab/reviews/list`,{
                    "review_type": this.props.review_type,
                    "review_header_id": this.props.reviewerId||''
            });   
            if(res&&res.data){
              
            }
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
    }
    getReviewId=(data)=>{
        if (data[this.review_status_column] && data[this.review_status_column].length > 0 && data[this.review_status_column][0]["status"] === "SUBMITTED") {
            return data[this.review_status_column][0]["id"];
        }  
        return null;
 }
    getDocumentList= async()=>{
        try{
            let{is_coordinator}=this.state;
            this.setState({showLoader:true});
            let {submission_id,reviewerId}=this.props;
            const res = await API.post(`reviewConcept/document/list`,{
                "submission_id": submission_id,
                "review_header_id":reviewerId
            });           
            if(res&&res.data){
                let sourceData=res.data||[];
                if(!is_coordinator){
                    sourceData= (res.data||[]).filter(p=>p.reviewer_system_id===this.context.userSystemId)
                }
                this.setState({source:sourceData,showLoader:false});
            }
            let s3Files=await this.getS3FilesList(submission_id);
            if(s3Files){
                s3Files.forEach((item)=>{
                    item.s3=true;
                })
            }
            this.setState({s3Files:s3Files,showLoader:false});
            await this.listSharepointFiles();
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
    }
    getS3FilesList = async (submission_id) => {
        let dataResponse=[];
        try {
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            dataResponse=res.data||[];
        } catch (e) {
          
            console.log(e)
        }
       return dataResponse;
    }
    listSharepointFiles=async ()=>{
      
        if(this.props.users&&this.props.users.length>0){
            const res = this.props.users[0].reviewers_details||[];
            let {submission_id,review_type,reviewerId}=this.props;
            const resultArray = res.map(elm => ({
                reviewers_detail_id: elm.id,
                reviewer_system_id:elm.reviewer_system_id,                
                review_header_id:reviewerId,
                submissionId:submission_id,
                review_type,
            }));
            let sc = new SharepointConnect();
            let stateInfo = {};
            await sc.loadConfigInformation().then((data) => {
                stateInfo = data;
            });
            let responseCollection = [];
            resultArray.forEach((item, index) => {
                sc = new SharepointConnect(Object.assign(stateInfo, item));
                let data = sc.fetchFiles();
                responseCollection.push(data);
            });
            let records = [];
            await Promise.all(responseCollection)
                .then((d) => {
                    if(d&&d.length>0){
                        d.forEach((dx,inx)=>{
                            records = [...records, ...dx];
                        })
                    }
                   
                });
                debugger;
                if(!this.state.is_coordinator){
                    let system_info=resultArray.find(p=>p.reviewer_system_id===this.context.userSystemId);
                    records= (records||[]).filter(p=>p.reviewers_detail_id===system_info.reviewers_detail_id)
                }
            let sharepointFiles = records.map((item, index) => {
                let { LinkingUrl, Name, TimeCreated, TimeLastModified, UniqueId, reviewers_detail_id ,ServerRelativeUrl} = item;
                return {
                    key: Name,
                    id: UniqueId,
                    createdAt: TimeCreated,
                    reviewer_name: res.find(p => p.id === reviewers_detail_id)?.reviewer_name ?? "",
                    LinkingUrl,
                    ServerRelativeUrl
                }
            })
            this.setState({ sharepointFiles: sharepointFiles });
          
        }
        
    }
    EmptyDocument=()=>{
        let {showLoader}=this.state;
        return <blockquote className="blockquote">
            { !showLoader && <p className="mb-0">No Documents</p>}
        </blockquote>
    }
    handleActions=(option)=>{
        switch(option.type)
        {
            case 'DOWNLOAD':this.handleDownload(option.data);break;
            case 'DELETE':this.handleDelete(option.data);break;
            case 'EDIT':this.handleSharepointEdit(option.data);break;
            case 'NEW':
            
                let {reviewerId,submission_id,review_type}=this.props;
                let {externalurl}=this.state;
                let {source}=this.state;
                let keyData= source.filter(p=>p.reviewer_system_id===this.context.userSystemId);
                let reviewers_detail_id=-1;
                let {is_a_reviewer}=this.props;
                if(is_a_reviewer){
                    reviewers_detail_id=is_a_reviewer.id;
                }
                this.setState({formView:'NEW',formData:{reviewers_detail_id,reviewerId,submission_id,externalurl,review_type}}) ;   
            break;
            case 'CLOSE':this.setState({formView:'',formData:{}});break;
            case 'REFRESH':this.setState({formView:'',formData:{}},()=>{
                this.getDocumentList();
            });break;
        }
    }
    getExternalUrl=async ()=> {        
        let response ='';
        try {
             response = await API.get("user/externalurl");
        }
        catch (err) {
        }
        this.setState({
        externalurl : (response && response.data) ? response.data.exturl : "",
    })
    }
    handleSharepointEdit=(data)=>{
        const link = document.createElement('a');
        link.href = data.LinkingUrl??"";
        link.target="_blank";
        document.body.appendChild(link);
        
        link.click();
        link.parentNode.removeChild(link);    
    }
    handleSharepointDownload=async(data)=>{
        let sc = new SharepointConnect();
        let stateInfo = {};
        await sc.loadConfigInformation().then((data) => {
            stateInfo = data;
        });
       await sc.downloadFile(data.ServerRelativeUrl,data.key);

    }
    handleSharepointDelete=async(data)=>{
        let sc = new SharepointConnect();
        let stateInfo = {};
        await sc.loadConfigInformation().then((data) => {
            stateInfo = data;
        });
       await sc.deleteFile(data.ServerRelativeUrl);
        this.getDocumentList();
    }
    handleDownload=(data)=>{
        if(data.ServerRelativeUrl){
            this.handleSharepointDownload(data);
            return;
        }
        this.setState({showLoader:true});
        try{
            let {reviewerId}=this.props;
            let {submiisionid,reviewers_detail_id}=data;//;
            let fileName=data.key.split("\/").pop();
            let key=`${submiisionid}-${reviewerId}-${reviewers_detail_id}`;
            let fileUrl = `${this.state.externalurl}/filedownload/Reviewer/${encodeURIComponent(key)}?name=${encodeURIComponent(fileName)}`;
            if(data.s3){
                let urlPart="filedownload/"+data.type+"/"+submiisionid;
               if(data.type.includes("Amendment")){
                    urlPart="multiple/filedownload/Amendment/"+submiisionid+"/"+data.type.split("_")[1];
               }
               if(data.type.includes("Publication")){
                urlPart="multiple/filedownload/Publication/"+submiisionid+"/"+data.type.split("_")[1];
           }
                fileUrl = `${this.state.externalurl}/${urlPart}?name=${fileName}`
            }
            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {                   
                        return response.blob();
                    }
                })
                .then((blob) => {        
                        
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));
                    document.body.appendChild(link);
                    this.setState({showLoader:false});  
                    link.click();
                    link.parentNode.removeChild(link);               
                });
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
        
    }
    handleDelete=(data)=>{
        swal("do you want to delete?", {
            buttons: {
              cancel: "Cancel",              
              yes: true,
            },
          })
          .then((value) => {
            switch (value) {           
              case "yes":
                this.doDeleteFile(data)
                break;
            }
          });
    }
    doDeleteFile=async(data)=>{
        if(data.ServerRelativeUrl){
            this.handleSharepointDelete(data);
            return;
        }
        const res = await API.post(`reviewConcept/document/delete`, {
            "id": data.id,
            "updated_by": this.context.userSystemId
        });
        if (res && res.data) {
            showMessage({
                text: "Deleted Successfully!",
                icon: "success",
                button: "Ok",

            }, (e) => {
                this.getDocumentList();
            });
        }
    }
    handleCopyUrl=(e,data)=>{
        e.preventDefault();
        e.stopPropagation();
        if(data.LinkingUrl){
            navigator.clipboard.writeText(data.LinkingUrl);
            this.setState({showCopyInfo:true},this.clearCopyInfo);
        }
            
    }
    clearCopyInfo=()=>{
        setTimeout(()=>{
            this.setState({showCopyInfo:false}) 
        },500);
    }
    renderActionArea=(data)=>{
        let{is_coordinator,showCopyInfo}=this.state;
        return <div className="d-flex align-items-center justify-content-end">
            {data.LinkingUrl&&<ToolTipEx tooltip={"Copy Url"}><div className="position-relative">
                <span class="material-icons mr-2" onClick={e=>this.handleCopyUrl(e,data)}>
                 content_copy
             </span>
            {showCopyInfo&&<div style={{right:-30,top:0,zIndex:100}} className="shadow-sm p-1 rounded-sm position-absolute bg-white">
                 Copied !
             </div>}</div></ToolTipEx>}
           {data.LinkingUrl&&is_coordinator&&<ToolTipEx tooltip={DEFAULT.EDIT}><span className="material-icons mr-2" onClick={e=>this.handleActions({type:'EDIT',data})} style={{cursor:'pointer'}}>
                edit
            </span></ToolTipEx>} 
            <ToolTipEx tooltip={DEFAULT.DOWNLOAD}><span className="material-icons mr-2" onClick={e=>this.handleActions({type:'DOWNLOAD',data})} style={{cursor:'pointer'}}>
                file_download
            </span></ToolTipEx>
            {is_coordinator &&  <ToolTipEx tooltip={DEFAULT.DELETE}><span className="material-icons" onClick={e=>this.handleActions({type:'DELETE',data})} style={{cursor:'pointer'}}>
                delete
            </span></ToolTipEx>}
        </div> 
    }
    renderTable=()=>{
        let {source,showLoader,sharepointFiles,s3Files}=this.state;
        s3Files=(s3Files||[]).filter(p=>p.type!="Reviewer");
        let Columns=this.columns.filter(p=>!p.hidden).map((item,index)=>{
            return <th key={item.dataField+index} style={{textAlign:(item.align?item.align:'left')}}>{item.text}</th>
        });
       
      let Rows=  source.map((item,index)=>{
            return <tr key={item.id}>
                    {
                        this.columns.filter(p=>!p.hidden).map((col,Colindex)=>{
                            let cell=item[col.dataField];
                            let row=item;
                            return <td key={col.dataField+Colindex}>{(col.formatter?col.formatter(cell,row,index): item[col.dataField])}</td>
                        })
                    }                    
            </tr>
        });
        let s3FilesRow=  s3Files.map((item,index)=>{
            return <tr key={item.id}>
                    {
                        this.columns.filter(p=>!p.hidden).map((col,Colindex)=>{
                            let cell=item[col.dataField];
                            let row=item;
                            return <td key={col.dataField+Colindex}>{(col.formatter?col.formatter(cell,row,index): item[col.dataField])}</td>
                        })
                    }                    
            </tr>
        });
        let Rows2=  sharepointFiles.map((item,index)=>{
            return <tr key={item.id}>
                    {
                        this.columns.filter(p=>!p.hidden).map((col,Colindex)=>{
                            let cell=item[col.dataField];
                            let row=item;
                            return <td key={col.dataField+Colindex}>{(col.formatter?col.formatter(cell,row,index): item[col.dataField])}</td>
                        })
                    }                    
            </tr>
        });

        return  <Table >
                    <thead>
                        <tr>{Columns}</tr>
                    </thead>
                    <tbody>{Rows}{Rows2}{s3FilesRow}
                    {
                       ((!source || source.length<=0) && (!sharepointFiles || sharepointFiles.length<=0) && (!s3Files || s3Files.length<=0)) && <tr>
                            <td colSpan={this.columns.filter(p=>!p.hidden).length}>
                             {showLoader?"Loading":"No Records!"}    
                            </td>
                        </tr>
                    }
                    </tbody>
        </Table>
    }

    render(){
        let {showLoader,formView,formData,is_coordinator}=this.state;
        let {is_a_reviewer}=this.props;
       
        return <>
            <div style={{ minWidth: "75%", maxWidth: "75%", margin: "auto" }} className="d-flex position-relative flex-column justify-content-center">
                {is_a_reviewer&&<div className="d-flex align-items-center pl-3 pt-3 pb-3 pr-0 justify-content-end">
                <ToolTipEx tooltip={DEFAULT.ADD}>
                <DTAButton  onClick={(e) => this.handleActions({ type: 'NEW' })} icon="ADD" text={"Add Document"}></DTAButton>
                   
                    </ToolTipEx>
                </div>}
                <div className='d-flex w-100'>
                    {
                        showLoader && <this.Loader></this.Loader>
                    }
                    {
                        this.renderTable()
                    }
                </div>
            </div>
        {
            formView==="NEW"&&<NewDocument 
                
                onClose={e=>{
                this.handleActions({type:e});
            }} {...formData}></NewDocument>
        }
        </>
    }
}