import React from 'react';

import ExpanderDataTable from '../common/ExpanderDataTable';
import API from '../../api/apis';
import { dateStringformat } from '../../utility/helperFunctions';
import UserContext from '../../context/UserContext';
import ReviewSubItems from './ReviewSubItems';
import InitiateReview from './InitiateReview';
import mockData from './testData.json'
import { getSubmissionText, userPermissions ,showMessage, isCoordinator} from './commonFunctions';
import swal from 'sweetalert'
import DatatTableEx from '../common/DataTableEx';
import Dropdown from 'react-bootstrap/Dropdown';
import Review from './Review';
import ToolTipEx from '../common/ToolTipEx';
import { REVIEWS } from '../../TooltipInfo';
import { Switch } from 'react-router-dom';
import SharepointConnect from './SharepointConnect';
import DTAButton from '../common/Button/DTAButton';
import moment from 'moment';
import { GLOBAL_DATE_FORMAT, GLOBAL_DATE_FORMAT_NOTIME, iirDateFormat } from '../../utility/dateHelper';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div     
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
export default class ReviewList extends React.Component
{
    static contextType = UserContext;

    constructor(props){
        super(props);
        this.state={
            toReviewList:[],
            filterSource:[],
            activeForm:'',
            activeFormData:{},
            showLoader:false,
            userPermissions:{view:true},
            currentNotification:''
        }
        this._interval=React.createRef(null);
    }
    APIUrls={
        "GEN":'reviewConcept/submission/review',
        "PROTOCOL":'reviewsTab/protocolAmendment/review/'
    }
    formType={
        INITIATE_REVIEW:'INITIATE_REVIEW',
        COORDINATOR_REVIEW:'COORDINATOR_REVIEW'
    }
    columns = [
        {
            dataField: 'key',
            text: 'key',
            sort: true,
            hidden:true,
            csvExport: false,
            view_type:['GEN','PROTOCOL','PUBLICATION'],
            excludeFilter:true
        },
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
            view_type:['GEN']
        },
        {
            dataField: 'title_ex',
            text: 'Review ID',
            sort: true,
            view_type:['PROTOCOL']
        },
        {
            dataField: 'title_ex',
            text: 'Publication ID',
            sort: true,
            view_type:['PUBLICATION']
        },
        {
            dataField: 'review_type',
            text: 'Review Type',
            sort: true,
            view_type:['GEN']
        },
        {
            dataField: 'review_type',
            text: 'Task',
            sort: true,
            view_type:['PROTOCOL','PUBLICATION']
        },
        {
            dataField: 'due_date',
            text: 'Due Date',
            sort: true,
            view_type:['GEN']
        },
        {
            dataField: 'due_date',
            text: 'Assigned Date',
            sort: true,
            view_type:['PROTOCOL','PUBLICATION']
        },
        {
            dataField: 'assigned_by',
            text: 'Assigned By',
            sort: true,
            view_type:['GEN','PROTOCOL','PUBLICATION']
            
        },
        {
            dataField: 'reviewers',
            text: 'Reviewers',
            sort: true,
            formatter: (cell, row, rowIndex) =>{
                return <ToolTipEx tooltip={row.reviewers}><div  style={{maxWidth:'300px',whiteSpace:"nowrap", overflow:'hidden',textOverflow:'ellipsis'}}>{row.reviewers}</div></ToolTipEx>
            },
            view_type:['GEN','PROTOCOL','PUBLICATION']
        },
        {
            dataField: 'PI_lastname',
            text: 'PI Last Name',
            sort: true,
            view_type:['GEN']
        },
        //removing invite_propsal 
        // {
        //     dataField: 'invite_proposal',
        //     text: 'Invite Proposal',
        //     sort: true
        // },
        {
            dataField: 'date_review_completed',
            text: 'Date Review Completed',
            sort: true,
            view_type:['PROTOCOL','PUBLICATION']
        },
        {
            dataField: 'supported',
            text: 'Supported',
            sort: true,
            csvExport: false,
            view_type:['GEN','PROTOCOL','PUBLICATION']
            // formatter: (cell, row, rowIndex) => row.supported ? `0 / ${row.supported}` : ""
        },
        {
            dataField: 'supportedCsv',
            text: 'Supported',
            sort: true,
            hidden:true,
            view_type:['GEN','PROTOCOL','PUBLICATION'],
            excludeFilter:true,
        },
        {
            dataField: 'status',
            text: 'Status Health',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                let data=row;
               
                let is_coordinator = isCoordinator(this.context);
                return <div className="d-flex" style={{userSelect:'none'}}>
                    <span className='flex-grow-1'>{row.status}</span>
                    {is_coordinator&&<div>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                            <span className="material-icons">
                                more_vert
                            </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="bg-dark dd-menu-dark text-white" >
                                <Dropdown.Item eventKey="1" onClick={e=>{
                                    e.preventDefault();
                                    this.handleCoordinatorReview(data);}} className="text-white">Coordinator Documentation</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                            
                    </div>}
                </div>
            },
            view_type:['GEN','PROTOCOL','PUBLICATION']
        },
        {
            dataField: 'className',
            text: 'className',
            sort: false,
            hidden:true,
            view_type:['GEN','PROTOCOL','PUBLICATION'],
            csvExport: false,
            excludeFilter:true,
        }
        ];
        componentDidMount() {
            //console.log(this.context);
            // debugger;
            let permissionSet=userPermissions(this.context);
            if(permissionSet){
                this.setState({userPermissions:permissionSet});
            }
            this.getToReviewList(this.context.userSystemId);
            this.getLists();
        }
        componentWillUnmount(){
           
            if(this._interval){
                clearInterval(this._interval);
                this._interval=null;
            }
        }
        getLists=async()=>{
            let sc=new SharepointConnect();
            let stateInfo={};
            await sc.loadConfigInformation().then((data)=>{    
                stateInfo=data;
            });
           let data= await sc.getLists();
           
          // await sc.getFields("7efcbab8-2ddc-45c6-8b9b-66254750ce6f");

        }
        getColumns=()=>{
            let columns=this.columns.filter(p=>p.view_type.includes((this.props.review_type||'GEN').toUpperCase()));
            return  columns;
        }
        getReviewIDFormat=(d)=>{
            if(this.props.review_type){
                let rt=d.review_type.toUpperCase();
                if(["AMENDMENT","PROTOCOL"].includes(rt)&&d.protocol_data){
                    let pd=d.protocol_data;
                    if(rt==="PROTOCOL")
                    {
                        return `${pd.protocol_id},${iirDateFormat(pd.created_at)||''}`
                    }
                    if(rt==="AMENDMENT"){
                        return `${pd.amendment_id},${pd.protocol_version},${iirDateFormat(pd.created_at)||''}`
                    }
                }   
                else if(rt==="PUBLICATION")             {
                    let pd=d.publication_data;                   
                    let formatArray = [pd.format || '', pd.venue || '', iirDateFormat(pd.created_at,true)||''];
                    return formatArray.filter(p => p.length > 0).join(",");
                }
            }
          
        }
        getToReviewList = async (id) => {
           
            this.setState({showLoader:true});
            try{
                let formBody={
                    "submissionid": this.props.submissionId,
                    "review_type": this.props.review_type??"Concept"
                
                }
                let res= await API.post(this.APIUrls["GEN"], formBody);
                this.setState({showLoader:false});
                //.log(res);
              
                if(res&&res.data){
                   let reviewList= res.data.map((item,index)=>{    
                            
                       let { id,
                           submission_id,
                           investigator_last_name, stage,
                            assigned_by, reviewer_name,
                           reviewer_id, reviewers_details, review_status,review_type,assigned_by_id,review_id,review_for } = item;

                       let title = getSubmissionText(submission_id);
                       let title_ex=this.getReviewIDFormat(item);
                       //let {stage,investigator_last_name}=submission;
                       let is_coordinator = isCoordinator(this.context);
                       let is_a_reviewer=item["reviewers_details"].find(p => p.reviewer_system_id === this.context.userSystemId);
                       let due_date="";
                       if (!is_coordinator) {
                           let isReviewerExist = item["reviewers_details"].find(p => p.reviewer_system_id === this.context.userSystemId);
                           if (isReviewerExist) {
                               due_date = isReviewerExist["due_date"];
                           }
                        //    invite_proposal=(isReviewerExist?.is_proposal_invited?"Yes":"No");
                       }
                       else{
                        let duedateReviews= item["reviewers_details"].filter(p=>p.due_date&&p.due_date.length>0&&p.status!="SUBMITTED");
                        if(duedateReviews.length>1){
                            duedateReviews.sort((a, b)=> {
                                return new Date(a.due_date) - new Date(b.due_date);
                             });
                        }  
                        due_date=(duedateReviews.length>0?duedateReviews[0].due_date:""); 
                            
                       }
                       if(due_date&&due_date.length>0){
                        due_date=moment(due_date,"YYYY-MM-DD",false).format(GLOBAL_DATE_FORMAT_NOTIME);
                       }
                       let date_review_completed='';
                       if(item.date_review_completed){
                        date_review_completed= moment.utc(item.date_review_completed,false).local().format(GLOBAL_DATE_FORMAT);
                       }
                       let reviewers = reviewers_details.map((r, i) => r.reviewer_name).join(",");
                       let submittedList = reviewers_details.filter(p => p.status === "SUBMITTED").length;
                       let supported = `${submittedList} / ${reviewers_details.length}`;
                       let supportedCsv = `${submittedList} out of ${reviewers_details.length}`;
                       return {
                           key: id,
                           title,
                           title_ex,
                           date_review_completed,
                           stage,
                           due_date: due_date,
                           assigned_by,
                           reviewers,
                           "PI_lastname": investigator_last_name,
                        //    "invite_proposal": invite_proposal,
                           supported,
                           supportedCsv,
                           "status": review_status || "In Progress",
                           reviewer_id,
                           review_status,
                           is_a_reviewer,
                            reviewers_details,
                            review_type,
                            assigned_by_id,
                            review_id,
                            review_for
                       }
                    });
                   // console.log(reviewList);
                    let filterSource=await this.getFilterSource({data:reviewList});                   
                    this.setState({ toReviewList: reviewList || [] ,filterSource:[...filterSource]});
                    
                    //if((this.props.review_type||'GEN')==='GEN')
                    {
                        //console.log(">>>>>>>>>>>>>",(this.props.review_type||'GEN'));
                        this.runNotificationFetch();
                    }
                   
                }
                else{
                    this.setState({ toReviewList: [] ,filterSource:[]});
                }
            }
            catch(exp){
                console.error("Reviewer List Error>>",exp);
            }
            finally{
                this.setState({showLoader:false}); 
            }
            

           //const res = await API.get(`review/reviewer/list/${id}`);
           // this.setState({ toReviewList: res.data || [] });

        //    let filterCollection= this.columns.map((col,index)=>{
        //         let columnValues=(res.data || []).map((item,key)=>{
        //             if(col.dataField.split(".").length>1){
        //                 return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
        //             }
        //             return item[col.dataField];
        //         });
        //         return {group:col.text,items:[...new Set(columnValues)]};
        //     });
        //     console.log(filterCollection);
        }
        onExpandAll =(isExpanded)=>{
            if(isExpanded){
                let {toReviewList}=this.state;
                toReviewList[0].expanded=true;
                this.setState({toReviewList})
            }
        }
        getFilterSource=async (res)=>{
            if(res&&res.data&&res.data.length>0){
                let filterCollection= this.columns.filter(k=>!k.hidden).map((col,index)=>{
                    let columnValues=(res.data || []).map((item,key)=>{
                        if(col.dataField.split(".").length>1){
                            return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
                        }
                        return item[col.dataField];
                    });
                    return {group:col.text,key:col.dataField,items:[...new Set(columnValues)]};
                });
                return filterCollection;
            }
            return null;
              
        }
        handleRowDetails=(row)=>{   
            return <ReviewSubItems review_type={row.review_type} 
            is_a_reviewer={row.is_a_reviewer} 
            handleSubFormCallback={this.handleSubFormCallback} 
            submission_id={this.props.submissionId} 
            users={[{reviewers_details:row.reviewers_details,reviewer_id:row.reviewer_id,assigned_by:{id:row.assigned_by_id,name:row.assigned_by}}]}
            rowStatus={row.status}

            reviewerId={row.key}
            review_for={row.review_for} />
        }
        handleEditReviewers=(data)=>{
            this.setState({activeForm:this.formType.INITIATE_REVIEW,activeFormData:data});
        }
        handleSubFormCallback=(option,data)=>{
          
            switch(option){
                case "UPDATE-NOTIFICATION":this.collectNotifications(true);break;
                case "REFRESH": this.getToReviewList();break;
                case "EDITREVIEWERS": this.handleEditReviewers(data);break;
            }
        }
        handleFormEvents=(option)=>{
            this.setState({activeForm:option,activeFormData:{}});
        }
        handleCoordinatorReview=(data)=>{
           let {submissionId}=this.props;
            let {activeForm,activeFormData}=this.state;
            activeForm=this.formType.COORDINATOR_REVIEW;
           
            activeFormData={
                "submission_id": submissionId,
                review_header_id:data.key,
                isCoordinatorApproval:true,
                formType:"COORDINATOR",
                review_type:data.review_type,
                review_submission_id:data.review_id,
                "review_for": data.review_for
            }
            this.setState({activeForm,activeFormData});
        }
        handleEvents=(event,data)=>{
            switch(event){
                case "CANCEL-VIEW": this.setState({activeForm:"",activeFormData:{}});break;
            }
        }
        handleOnModalclose=(e)=>{
            this.setState({activeForm:"",activeFormData:{}},()=>{
                this.getToReviewList();
            });
        }
        handleModalCallBack=(response)=>{
            if(response===true){
                let callBack=(val)=>{
                    this.setState({activeForm:''},()=>{
                        this.getToReviewList();
                    });
                  }
                  showMessage({                  
                    text: "Saved Successfully",
                    icon: "success",
                    button: "Ok",
                  
                  },callBack);
            } 
            else{
                this.setState({activeForm:''});
            }           
        }
        changeRowStyle=(sourceData,keyField,isClear)=>{
            (sourceData || []).forEach((rowItem) => {
                let allNodes = document.getElementsByClassName(`dx_${rowItem[keyField]}`);
                if (allNodes && allNodes.length > 0) {
                    allNodes[0].classList[isClear?"remove":"add"]('font-weight-bold');
                }
            });
        }
        checkAnyNotificationExist=()=>{
          
            let sysID=this.context.userSystemId;
            let notificationList=sessionStorage.getItem("notification_sub_" + sysID);
            let {  toReviewList } = this.state;
            let isAny=false;
            if(notificationList){
                let parsedResponse=JSON.parse(notificationList);
                if(parsedResponse&&parsedResponse.length>0){                  
                    let exist=parsedResponse.find(p=>p.submission_id.toString()===this.props.submissionId);
                    if(exist){
                        (exist.sub_keys||[]).forEach((item)=>{
                            let any=toReviewList.find(p=>p.key===item);
                            if(any){
                                isAny=true;
                                return;
                            }
                        })
                    }
                }   
            }
            return isAny;
        }
        collectNotifications=async(skipZeroChecking)=>{
            // if((!this.props.review_type||'GEN')!='GEN'){
            //     return;
            // }
           // console.log(">>>>>>> collectNotifications >>>>>>",(this.props.review_type||'GEN'));
            let anyNotification=sessionStorage.getItem("notification_"+this.context.userSystemId);
            let total=parseInt(anyNotification);
           // console.log(total);
            if((skipZeroChecking||(anyNotification&&total>0))&&this.checkAnyNotificationExist())
            {
                let { currentNotification, toReviewList } = this.state;
                currentNotification = (currentNotification === "protocol" ? "proposal" : "protocol");
                let response = await API.post("reviewConcept/reviewer/notification", {
                    "system_id": this.context.userSystemId,
                    "submission_id": [this.props.submissionId]
                });
                if (response && response.data) {
                    try {
                        this.changeRowStyle(toReviewList, "key", true);
                        this.changeRowStyle(response.data || [], "ref_key", false);
                    }
                    catch (ex) {
    
                    }
                }
            }
            
        }
        runNotificationFetch=()=>{
            if(this._interval){
                clearInterval(this._interval);
            }
            this._interval=setInterval(this.collectNotifications,5000);
        }
        handleRowClasses=(row, rowIndex)=>{             
            return "dx_"+row.key;
        }
    render(){
        let {userPermissions}=this.state;
        let is_coordinator = isCoordinator(this.context);
            return <div style={{paddingTop:'1rem'}}>
                <DatatTableEx
            id="review_list_details"
            title="Items Needing Review"
            data={this.state.toReviewList}
            columns={this.getColumns()}
            isExpander={true}
            onExpandAll={this.onExpandAll}
            pagination="true"
            showLoader={this.state.showLoader}
            rowClasses={this.handleRowClasses}
            onClick={e=>{e.preventDefault();}}
            autoFilter={true}
            filterChange={()=>{}}
            pageIcons={is_coordinator && <ToolTipEx tooltip={REVIEWS.INITIATE}>
                <DTAButton onClick={() => this.handleFormEvents(this.formType.INITIATE_REVIEW)} icon="ADD" text={['PROTOCOL','PUBLICATION'].includes((this.props.review_type??'').toUpperCase())?REVIEWS.INITIATE_EX:REVIEWS.INITIATE}></DTAButton>
                {/* <span class="material-icons ml-2"  onClick={e=>this.handleFormEvents(this.formType.INITIATE_REVIEW)} style={{cursor:'pointer' }}>add</span> */}
            </ToolTipEx>}
            handleRowDetails={this.handleRowDetails}
            csvName="review_list_details" />
            {this.state.activeForm===this.formType.INITIATE_REVIEW && <InitiateReview submissionId={this.props.submissionId} {...this.state.activeFormData} show={true} onClose={this.handleModalCallBack}></InitiateReview>}
            {this.state.activeForm===this.formType.COORDINATOR_REVIEW && <Review onClose={this.handleOnModalclose} data={this.state.activeFormData} modalInfo={{title:"",titleDescription:"",show:true}} handleEvents={this.handleEvents} ></Review>}
            </div>
    
    }
}