import { SERVICE_ENV } from "../Constants";

export const getFilterRequest=(source)=>{
    let requestBody={users:null, therapeutic_area:null, stage:null, request_type:null,trial_alias:null,country:null,submission_id:null};
    let maps={'assigned_to':'users'};
    Object.entries(source).forEach(([key,value])=>{
      if(value){
        let selected_data=value.map(p=>{
          return (p.id||p.value);
        });
        requestBody={...requestBody,[maps[key]||key]:selected_data};
      }
    });
    return requestBody;
}
export const getServiceDomain=()=>{
  if(window.location.host.includes("stg"))
    return SERVICE_ENV.QA;
  else  if(window.location.host.includes("dev")||window.location.host.includes("localhost"))
    return SERVICE_ENV.DEV;
  if(window.location.host.includes("prod"))
    return SERVICE_ENV.PROD;
  return "//";
}

export const getSecurityHeader=(method="POST")=>{  
    let token=localStorage.getItem("token");
    const bearer = "Bearer " + token;
    let config = {
      method,
      headers: {
         'Content-Type':'application/json',
        "Authorization": bearer
      }
    }
    return config;
}