import React from 'react'
import {Modal,Button, Row, Col} from 'react-bootstrap';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import InputField from '../../common/input-fields/InputField';
import Form from 'react-bootstrap/Form';
import { showMessage} from '../commonFunctions';
import {DateTime} from "luxon"
import { yyyymmddFormat } from '../../common/dateFormat';
import PropTypes from 'prop-types';
import ReviewForm from './ReviewForm';
export default class Review extends React.Component{
   constructor(props) {
    super(props)
   }
    
    render(){
        let {modalInfo}=this.props;
        let {isCommiteeReview}=this.props.data;
        return <Modal
        size={(isCommiteeReview?"md":"xl")}
        show={modalInfo.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
               <Modal.Header style={{ border: 0 }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="font-weight-bold">&nbsp;</h5>
                    <div style={{ fontSize: '1rem' }}>
                        {/* <span>{modalInfo.titleDescription||''}</span> */}
                    </div>
                </Modal.Title>
               
                  <div className='d-flex justify-content-end align-items-center' >
                    {!isCommiteeReview&&<span className="material-icons mr-3" onClick={e=>{
                        let sessionKey=`review-tab-data`;
                        sessionStorage.removeItem(sessionKey);
                        let data=this.props.data;
                        data.isNewWindow=true;
                        sessionStorage.setItem(sessionKey,btoa(JSON.stringify(this.props.data)));
                         window.open("/submit-review/", "_blank");
                        if(this.props.onClose){
                            this.props.onClose();
                        }
                    }} style={{cursor:'pointer'}}>
                        open_in_new
                    </span>}
                    <span className="material-icons" onClick={this.props.onClose} style={{cursor:'pointer'}}>
                        close
                    </span>
                </div>
               
                
               
            </Modal.Header>
            <Modal.Body className="new-flow-modal">
                <ReviewForm {...this.props.data} onClose={this.props.onClose}></ReviewForm>
            </Modal.Body>
        </Modal>
    }
}
