import React, { Component } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import DatatableComponent from '../../../common/datatable/DatatableComponent';
import axios from 'axios';
import FileComponent from '../../../common/file-component/FileComponent';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import API from '../../../../api/apis';
import swal from 'sweetalert';
import { uploadFile, enableWFActions } from '../../../../utility/helperFunctions';

import Loader from '../../../common/loader/Loader';
import UserContext from '../../../../context/UserContext';

export default class FilesInvShare extends Component {
    static contextType = UserContext;
    state = {
        externalurl :  "http://localhost:1337",
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },        fileList: [],
        showTable : false ,
        selectedFile: null,
        successModal: false,
        errorModal: false,
        action : "",
        showLoaderUpload :  false
    }
    columns = [{
        dataField: 'File Name',
        text: 'File Name',
        sort: true
    }, {
        dataField: 'Actions',
        text: ''
    }];

    async getExternalUrl() {
        let response ='';
        try {
             response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
        externalurl : (response && response.data) ? response.data.exturl : "http://localhost:1337",
    })
    }

    async componentDidMount() {
        await this.getExternalUrl();
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
            showLoader :  true,
            action : "fetching" , 

            submissionId: this.props.submissionDetails?.id,
        },
            () => this.getFilesList(this.props.submissionDetails?.id));

    }
    
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
        this.setState({
            selectedRole: selectedRole,
            access: {
           view: true,
           create:  selectedRole.includes("administrator"),
           edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
           delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
       },
        })
    }
    }
    getFilesList = async (submissionId) => {
        this.setState({
            showTable :  false
        })
        try {
            const res = await API.get(`/user/proposals3/getByid/Submission/${submissionId}`);
            this.setState({
                fileList: res.data,
                showTable :  true,
                showLoader :  false , 
            });
        } catch (e) {
            console.log(e)
        }
    }

    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }
    

    uploadFileFunction = async () => {
        const formData = new FormData();
        this.setState({
            showLoaderUpload :  true,
        })
        formData.append(
            'file',
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        formData.append(
            'created_by',
            this.context.userSystemId
        );
        try {
            let fileUrl = `${this.state.externalurl}/fileupload/Submission/${this.state.submissionId}`;
            let res = await axios.post(fileUrl, formData, {
                headers: {                   
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                },
            });
            // const res = await API.post(`/user/proposals3/upload/Submission/${this.state.submissionId}`, formData);
            this.setState({
                selectedFile: null,
                successModal: true,
                fileMessage: "File uploaded!",
                showLoaderUpload : false
            })
            await this.getFilesList(this.state.submissionId);

        } catch (e) {
            this.setState({
                selectedFile: null,
                errorModal: true,
                showLoaderUpload :  false,
            })
            console.log(e);
        }
    }
    handleDelete = async (fileName,type) => {
    
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({showLoader :  true , action : "deletion"})
                        const res =await  API.post(`/user/proposals3/delete/${type||'Submission'}/${this.state.submissionId}/${fileName}`, {updated_by : this.context.userSystemId});
                        this.setState({
                            // selectedFile: null,
                            successModal: true,
                            fileMessage: "File deleted!"
                        })
                         this.getFilesList(this.state.submissionId);

                    }
                    catch (err) {
                        this.setState({ errorModal: true,
                            showLoader :  false, });
                             this.getFilesList(this.state.submissionId);

                    }

                }
            });

    }

    handleDownload = async (fileName,type) => {
        try {
            this.setState({
                showLoader :  true , 
                action : "download"
            })
           /*
            let fileUrl = `${this.state.externalurl}/filedownload/Submission/${this.state.submissionId}?name=${fileName}`
            const res = await axios.get(fileUrl, {
                headers: {
                    'responseType': 'blob',
                    'Content-Type': 'application/json',

                },
            });
             // const res = await API.get(`/user/proposals3/download/getByid/Submission/${this.state.submissionId}/${fileName}` ,{
            //     responseType: 'blob',
            // });
            const url = window.URL.createObjectURL(new Blob([(res.data)] , {type:"application/pdf"}));
            // const url = window.URL.createObjectURL(res.data);

            const link = document.createElement("a");
            
            link.href = url;
            link.setAttribute('download', decodeURIComponent(fileName));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                successModal: true,
                fileMessage: "File Downloaded Successfully!",
                showLoader :  false
            })*/
            let fileUrl = `${this.state.externalurl}/filedownload/${type||'Submission'}/${this.state.submissionId}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                       
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        successModal: true,
                        fileMessage: "File Downloaded Successfully!",
                        showLoader :  false
                    })
                });
        } catch (e) {
            this.setState({
                errorModal: true,
                showLoader :  false 
            })
            console.log(e);
        }
    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    onFileChange = event => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
    };


    render() {
        let { fileList, submissionDetails,fileMessage,showTable, showLoaderUpload  , action ,  showLoader, successModal, errorModal, success_body } = this.state;

        return (
            <>               
               
                {this.state.access.edit && enableWFActions() && <Row>
                    <Col sm={8}>
                        <Form.File
                            id="custom-file"
                            className="custom-file-label"
                            onChange={this.onFileChange}
                            label={this.state.selectedFile?.name}
                            custom
                        />
                    </Col>
                    <Col sm={4}>
                        {showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </Button>}
                        {!showLoaderUpload && <Button variant="secondary" onClick={()=> uploadFile(this.state.selectedFile,this.state.fileList,this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>}
                    </Col>
                </Row>}

                {showLoader && 
                <Row>
                    <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                    <span className="visually-hidden">File  {action} in progress </span>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
                
                </Row>}


                <br />
                <Row>

                    {this.state.fileList.length > 0 && showTable &&  <Col md={12}> 
                        <FileComponent data={this.state.fileList}
                            accessDelete={this.state.access.delete}
                            handleDelete={this.handleDelete}
                            handleDownload={this.handleDownload} /></Col>
                    }
                </Row>
                {
                    this.state.successModal &&
                    <BootstrapModal
                        show={successModal}
                        header="Success"
                        body={fileMessage}
                        changeShowState={this.changeShowState}
                    />
                }
                {
                    this.state.errorModal &&
                    <BootstrapModal
                        show={errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </>
        )
    }
}
