import React from 'react'
import Tab from '../Tab'
import Comments from './Comments';
import Documents from './Documents';
import InstructionList from './InstructionList';
import ReviewerList from './ReviewerList';
import UserContext from '../../../context/UserContext';
import API from '../../../api/apis';
import { isCoordinator } from '../commonFunctions';
import SwitchHistory from '../../common/SwitchHistory';
import { OBJ_TYPES } from '../../common/SwitchHistory/historyConsts';
export default class ReviewSubItems extends React.Component{
    static contextType=UserContext;
    constructor(props){
        super(props);
        this.state={
            notifications:[],
            tabSource:[ {key:'Instructions',text:'Instructions',isActive:true},
            {key:'Documents',text:'Documents',isActive:false},
            {key:'Reviews',text:'Reviews',isActive:false},
            {key:'Comments',text:'Comments',isActive:false},
            {key:'History',text:'History',isActive:false}
           ]
        }
        this.interval=15000;
        this._notificationThread=React.createRef();
    }
     mapping={
        "Reviewer":"Reviews",
        "Instruction":"Instructions",
        "Document":"Documents",
        "Comment":"Comments"
    }
    componentDidMount(){
        let _isCoordinator=isCoordinator(this.context);
        this.setState({_isCoordinator},this.getNotification);
        
    }
    runPeriodicFetch=()=>{
        this.clearThread();
        this._notificationThread.current=setInterval(this.handlePeriodicFetch,this.interval);
    }
    clearThread=()=>{
        if (this._notificationThread.current) {
            clearInterval(this._notificationThread.current)
        }
    }
    handlePeriodicFetch=async()=>{
        let anyNotification=sessionStorage.getItem("notification_"+this.context.userSystemId);
        let total=parseInt(anyNotification);
        if(anyNotification&&total>0)
        await this.getNotification(true);
    }
    handleEvents=(event,data)=>{
        switch(event){
            case 'TAB-CHANGE':this.handleTabChange(data);break;
        }
    }
    componentWillUnmount(){
        this.clearThread();
       
    }
    updateNotification=async()=>{
        try{
         
            let {tabSource}=this.state;
            let activeTab=tabSource.filter(p=>p.isActive);       
            let refType=Object.entries(this.mapping).find(p=>p[1]===activeTab[0].key)?.[0]??"";
            if(refType&&refType.length>0){
                const res = await API.post(`reviewConcept/notification/updateStatus`,{
                    "system_id": this.context.userSystemId,
                    "updated_by": this.context.userSystemId,
                    "ref_type":  refType,
                    "review_header_id":this.props.reviewerId||''
            });   
            this.getNotification();
            }
            this.refreshParentNotification();
        }
        catch(exp){

        }      
       
    }
    refreshParentNotification=()=>{
        if(this.props.handleSubFormCallback){
            this.props.handleSubFormCallback("UPDATE-NOTIFICATION");
        }
    }
    getNotification=async ()=>{
        this.clearThread();
        try{
            this.setState({showLoader:true});
            sessionStorage.setItem("notification_"+this.context.userSystemId,0);
            let params={ "review_header_id": this.props.reviewerId||''};
           // if(!this.state._isCoordinator){
                params.system_id=this.context.userSystemId;
            //}
            const res = await API.post(`reviewConcept/notification/fetch/data`,params);   
            if(res&&res.data){
               
                let notifications=[];
               
                res.data.map((item,index)=>{
                    let anyData=item?.notification_details?.filter(p=>!p.read_status)??[];
                    if(anyData.length>0){
                        notifications.push(this.mapping[item.ref_type]);
                    }
                })
                this.setState({notifications:notifications||[],showLoader:false});
            }
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
        this.runPeriodicFetch();
    }
    handleTabChange=(data)=>{
      
        let {tabSource}=this.state;
        let activeTab=tabSource.filter(p=>p.isActive);
        if(activeTab.length>0)
        {
            activeTab[0].isActive=false;
        }
       let current= tabSource.find(p=>p.key==data.key);
       current.isActive=true;
       this.setState({tabSource},()=>{
        this.updateNotification();
       });
    }
    renderTabComponent=()=>{
        let {tabSource}=this.state;
        let activeTab=tabSource.find(p=>p.isActive)||{};
       
        switch(activeTab.key){
            case "Instructions":return <InstructionList users={this.props.users} review_type={this.props.review_type} reviewerId={this.props.reviewerId} />;break;
            case "Documents":return <Documents is_a_reviewer={this.props.is_a_reviewer} review_type={this.props.review_type} users={this.props.users}  submission_id={this.props.submission_id} reviewerId={this.props.reviewerId} ></Documents>;break;
            case "Comments":return <Comments is_a_reviewer={this.props.is_a_reviewer} review_type={this.props.review_type} users={this.props.users} submission_id={this.props.submission_id} reviewerId={this.props.reviewerId} ></Comments>;break;
            case "Reviews":return <ReviewerList rowStatus={this.props.rowStatus} review_type={this.props.review_type} submission_id={this.props.submission_id} handleSubFormCallback={this.props.handleSubFormCallback} reviewerId={this.props.reviewerId} review_for={this.props.review_for}></ReviewerList>; break;
            case "History": 
                return <SwitchHistory 
                            object_type={OBJ_TYPES.REVIEWER} 
                            object_id={this.props.reviewerId||''} 
                            showRowType={true}
                            showNewWindow={true}
                            submission_id={this.props.submission_id}
                       />;
                break;
        }
    }
    render(){
        let {tabSource,notifications}=this.state;
        return <div>       
           <div style={{paddingLeft:'100px'}}>
                 <Tab notifications={notifications} source={tabSource} onChange={this.handleEvents} /> 
            </div>  
            <hr className="m-0"></hr>  
            <div className='pb-5'>
                {this.renderTabComponent()}
            </div>
        </div>
    }
}