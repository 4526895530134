import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { Component } from 'react'
import {  Col, Modal, Row, Button as ButtonEx,Table } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import UserContext from '../context/UserContext';
import { dateChange,dateStringformat, dateTimeformat, getUserInfo } from '../utility/helperFunctions';
import { yyyymmddFormat } from './common/dateFormat';
import InputField from './common/input-fields/InputField';
import Loader from './common/loader/LoaderEx';
import API from '../api/apis';
import BootstrapModal from './common/modal/BootstrapModal';
import Button from './common/Button'
import { isCoordinator, showMessage } from './ToReviewDetails/commonFunctions';
import axios from 'axios';
import swal from 'sweetalert';
import FileUploader from './ToReviewDetails/FileUploader';
import ToolTipEx from './common/ToolTipEx';
import { DEFAULT } from '../TooltipInfo';

export default class AmendmentModalEx2 extends Component {
    static contextType=UserContext
    state = {
      
        // owner_id:"",
        protocol_version_number:"",
        approval:"Awaiting Review",
        decision_date :  null,
        protocol_version_date:null,
        submissionId:"Submission-"+this.props.submissionId,
        isLoader:true,
        uploadedFileList:[],
        s3FileInfo:{}
    }
    get inputFieldsArray() {
        return [
            {
                label: "Protocol Version",
                type: "text",
                name: "protocol_version_number",
            },
            {
                label: "Protocol Version Date",
                type: "date",
                name: "protocol_version_date",
            },
           
            {
                label: "Approval",
                type: "dropdown",
                placeholder: "Awaiting Review",
                name: "approval",
                options: [
                    "None",
                    "Awaiting Review",
                    "Approved",
                    "Declined",
                    "Under Review"
                ]
            },
            {
                label: `Decision Date ${this.state.approval === "Approved" || this.state.approval === "Declined"  ? "*" : ""}`,
                name:  "decision_date",
                type:  "date",
                //disabled: this.state.approval === "Approved" || this.state.approval === "Declined"  ? false : true,
                maxDate:new Date()
            },
            {
                label: "Lilly Feedback",
                type: "textarea",
                name: "feedback"
               },
               {
                label: "[EXT] Investigator Rationale",
                type: "textarea",
                name: "investigator_rationale",
            }            
        ];
    }
    get auditInputs(){
        return [
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                readonly:true
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                readonly:true
            }
        ]
    }
   async componentDidMount() {
       this.getAmendmentData();
    }
    
    onInputChange = async(e,eo) => {
        let { name, value,type } = eo;
        if (eo.type === "date" || eo.type==="text-editor") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                hasChanged: true
            });
        }
       
        else 
        { this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }
    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
            error:{}
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = this.state.approval === "Approved" || this.state.approval === "Declined"  ? 
        { decision_date: this.state.decision_date } : {}
        const errors = {}
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors }
            });
        });
        return is_valid;
   }
   
    onSave = () => {
        if (this.formValidation()) {
            this.setState({showLoader:true,isIUDOperation:true},()=>{
                  this.handleSave();
            });
          
        }
    }
   
    renderInputFields = () => {
        let {amendment_id}=this.props;
        let audit_fields=[];
        let fields= this.inputFieldsArray.map(field => {
           
            return (
                <Col sm={12} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={(e)=> this.onInputChange(e,field)}
                        disabled = {field.disabled ? field.disabled : !isCoordinator(this.context)}
                    />
                </Col>
            )
        });
        if(amendment_id){
            audit_fields= this.auditInputs.map(field => {
           
                return (
                    <Col sm={12} key={field.label}>
                        <InputField
                            {...field}
                            value={this.state[field.name]}
                        />
                    </Col>
                )
            });
        }
        return [...fields,...audit_fields];
    }
    clearFormData=(callBack,isSuccess)=>{
        this.setState({
            ...this.INITIAL_STATE
        },()=>{callBack(isSuccess);});
    }
    onHide=()=>{
        let {onClose}=this.props;
        if(onClose){   
            onClose({});
        }
    }
    getAmendmentData=async()=>{
        const d=await this.getExternalUrl();
        let {amendment_id,submissionId}=this.props;
        if(amendment_id){
            this.setState({showLoader:true});
            const res = await API.get(`amendment/getbyid/${amendment_id}`); 
            let S3Files= await this.getFilesList(submissionId,amendment_id);
            let source={};
            if(res.data&&res.data.submission_id){
                source["protocol_version_number"]=res.data.protocol_version_number !==null ? res.data.protocol_version_number : '';
                source["feedback"]= res.data.feedback ;
                source["investigator_rationale"]=res.data.investigator_rationale !== null ? res.data.investigator_rationale.replace(/<[^>]+>/g, '') : ""; 
                source["protocol_version_date"] = res.data.protocol_version_date !== "" && res.data.protocol_version_date !== null ? dateStringformat(res.data.protocol_version_date) : null;           
                source["decision_date"] = res.data.decision_date !== "" && res.data.decision_date !== null ? dateStringformat(res.data.decision_date) : null;
                source["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
                source["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt):res.data.createdby ;
                source["approval"]=res.data.approval;
            }
            this.setState({...source,S3Files:S3Files||[],showLoader:false,originalSource:JSON.parse(JSON.stringify(source))});
        }
        
    }
    getFilesList = async (submissionId,id) => {
        try {
            const res = await API.get(`/user/proposals3/getByid/${submissionId}`);
            let files=res.data?.filter(file => file.type === `Amendment_${id}`&&!file.is_deleted);
            return files;
        } catch (e) {
           
        }
    }
    getFormValues=()=>{
        let {protocol_version_number,protocol_version_date,approval,decision_date,feedback,investigator_rationale,originalSource}=this.state;
        let {submissionId,amendment_id}=this.props;
        decision_date   = decision_date!= "" && decision_date!= null ? dateChange(decision_date) : null;       
        protocol_version_date = protocol_version_date!= "" && protocol_version_date!= null ? dateChange(protocol_version_date) : null;
        let created_by  = this.context.userSystemId;
        let response    = {protocol_version_number,protocol_version_date,approval,decision_date,feedback,investigator_rationale,created_by,submission_id:+submissionId};
      
        if(amendment_id){
            delete response.updatedby;
            delete response.createdby;
            delete response.created_by
            response.id         =amendment_id;
            response.updated_by =this.context.userSystemId;
            if(originalSource){
                //ensuring only required data 
                originalSource.decision_date=originalSource.decision_date!= "" && originalSource.decision_date!= null ? dateChange(originalSource.decision_date) : null;
                for (const key in originalSource) {
                        if(originalSource[key]===response[key]){
                            delete response[key];
                        }
                  }
            }

        }


        return response;
    }
    handleSave=async()=>{
       
        let modal=null;
        try{
            let {amendment_id}=this.props;
            let inputs=this.getFormValues();
            let api_part="amendment/add";
            let method="post";
            if(amendment_id){           
                api_part="amendment/update";
                method="put";
            }
            const res = await API[method](api_part, inputs);           
            if(res&&res.data&&(res.data.id||Array.isArray(res.data))){
                await this.uploadFiles(res.data.id||amendment_id);
                modal="SUCCESS";
            }
            else{
                modal="ERROR";
            }
           
        }
        catch(exp){

        }
        finally{
            this.setState({modal,showLoader:false,isIUDOperation:false});
        }
        
        
    }
    async getExternalUrl() {
        let response = '';
        try {
            response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
            externalurl: (response && response.data) ? response.data.exturl : "http://localhost:1337",
        })
    }
    uploadFiles=async(id)=>{
        
        let {uploadedFileList}=this.state;
        let {submissionId,amendment_id}=this.props;
        if(uploadedFileList&&uploadedFileList.length>0){
            let formData = new FormData();
            uploadedFileList.forEach((item,index)=>{
                formData.append(
                    'file',
                    item.fileInfo,
                    item.fileInfo.name
                );
            })
            
            try {
                this.setState({
                    showLoader: true
                })
                
                let fileUrl = `${this.state.externalurl}/fileupload/multiple/Amendment/${submissionId}/${id||amendment_id}`;
                let res = await axios.post(fileUrl, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    },
                });
               
            } catch (e) {
              
                console.log(e);
            }
            this.setState({
                showLoader: false
            })
        }
        
    }
    changeShowState=()=>{
        let {modal}=this.state;
        if(modal==="SUCCESS"){
            this.onHide();
        }
    }
    getSuccessmessage=()=>{
        let {amendment_id}=this.props;
        if(amendment_id) return "Amendment has been updated!";
        return "Amendment has been created!";
    }
    handleRemoveFile=(item)=>{
        let {uploadedFileList}=this.state;
        uploadedFileList.splice(uploadedFileList.indexOf(item),1);
       this.setState({uploadedFileList:[...uploadedFileList]});
    }
    handleDownload = async (item) => {
        let {submissionId,amendment_id}=this.props;
        try {
            this.setState({
                showLoader: true
            })
            let fileName=(item.key||'').split('/').pop();
           
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Amendment/${submissionId}/${amendment_id}?name=${encodeURIComponent(fileName)}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response.ok) {
                        return response.blob();
                    }
                    else {                      
                        showMessage({                  
                            text: 'Error Encountered.Please try again.',
                            icon: "error",
                            button: "Ok",                          
                          },()=>{});
                        
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    showMessage({                  
                        text: "File Downloaded Successfully!",
                        icon: "success",
                        button: "Ok",
                      
                      },()=>{});
                });
        } catch (e) {          
        }
        finally{
            this.setState({
                showLoader: false
            });
        }
    }
    handleDelete = async (item) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({
                            showLoader: true
                        })
                        let fileName=(item.key||'').split('/').pop();
                        let {submissionId,amendment_id}=this.props;
                        const res = await API.post(`/user/multiple/delete/Amendment/${submissionId}/${amendment_id}/${encodeURIComponent(fileName)}`, { updated_by: this.context.userSystemId });

                        showMessage({                  
                            text: "File Deleted!",
                            icon: "success",
                            button: "Ok",
                          
                          },()=>{});
                    }
                    catch (err) {
                        showMessage({                  
                            text: "Failed!",
                            icon: "error",
                            button: "Ok",
                          
                          },()=>{});
                    }
                    finally{
                        this.getAmendmentData();
                    }

                }
            });


    }
    renderFileUploadArea=()=>{
        let {s3FileInfo,uploadedFileList,S3Files}=this.state;
        return <Col sm={12} >
        
                    <div className='d-flex flex-column mt-4' style={{marginLeft:'2%'}}>
                    {isCoordinator(this.context)&&<><h5 className='font-weight-bold'>Upload File</h5>
                        <span style={{fontWeight:500}}>Select a Document from your Computer</span>
                        <div className='d-flex' >
                        <div style={{width:'50%'}}> <FileUploader style={{marginLeft:0}} placeholder="Select File" fileInfo={s3FileInfo} onChange={e=>{
                        let activeFile= e.target.files[0];    
                        /*for blob url var path = (window.URL || window.webkitURL).createObjectURL(activeFile);   */
                        let path=(e.target.value||'').replace("fakepath","...");            
                        this.setState({s3FileInfo:{selectedFileName:path,file:activeFile}},()=>{                         
                        });
                    }}></FileUploader></div>
                 <ButtonEx size="sm"
                        style={{width:'75px', height:'37px'}}
                        className="d-flex align-items-center ml-3"
                        onClick={e=>{
                            if(s3FileInfo&&s3FileInfo.file){
                                let maxId=0;
                                 if((uploadedFileList||[]).length>0){
                                    let maxIdObj= (uploadedFileList||[]).reduce((p, c) => p.id > c.id ? p : c);
                                    maxId=maxIdObj.id+1;
                                 }                                
                                let isFileExists=(uploadedFileList||[]).find(p=>p.fileInfo.name.toLowerCase()===s3FileInfo.file.name.toLowerCase());
                                let isS3FileExist=(S3Files||[]).find(p=>(p.key||'').split('/').pop().toLowerCase()===s3FileInfo.file.name.toLowerCase());
                                if(!isFileExists&&!isS3FileExist){
                                    uploadedFileList.push({id:maxId,fileInfo:s3FileInfo.file});
                                    this.setState({uploadedFileList:[...uploadedFileList],hasChanged:true,s3FileInfo:{selectedFileName:'',file:null}});
                                }
                                else{
                                    showMessage({                  
                                        text: "File is already exist with the same name!",
                                        icon: "warning",
                                        button: "Ok",
                                      
                                      },()=>{});
                                }
                              
                            }
                          
                        }}
                        variant="dark">
                        <span className="material-icons mr-1 m-0">add </span>
                        <span style={{ fontSize: ' 0.75rem' }}>ADD</span>
                    </ButtonEx>
                        </div></>}
                    { ((uploadedFileList&&uploadedFileList.length>0) || (S3Files && S3Files.length>0)) &&<div style={{ maxHeight: '400px', overflowY: 'auto', fontSize: '.75rem' }}><Table responsive="sm">
                    <thead style={{ position: 'sticky', top: -2, backgroundColor: '#FFF', zIndex: 1, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <tr>
                            <th className='bg-white'>Uploaded Document</th>
                            <th className="text-right bg-white">Actions</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {
                             S3Files&&S3Files.map((item, index) => {
                                return <tr key={item.id}>
                                    <td>{(item.key||'').split('/').pop() ?? "File Name Missing"}</td>
                                    <td className="text-right">

                                        <div className='d-flex justify-content-end align-items-center'>
                                        <ToolTipEx tooltip={DEFAULT.DOWNLOAD}><span class="material-icons mr-2" style={{cursor:'pointer'}} onClick={e => {
                                              this.handleDownload(item);
                                            }} >
                                                cloud_download
                                            </span></ToolTipEx>
                                            {isCoordinator(this.context)&&<ToolTipEx tooltip={DEFAULT.DELETE}><span className="material-icons" onClick={e => {
                                               this.handleDelete(item);

                                            }} style={{ cursor: 'pointer' }}>
                                                close
                                            </span></ToolTipEx>}
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                        {
                            
                            uploadedFileList&&uploadedFileList.map((item, index) => {
                                return <tr key={item.id}>
                                    <td>{item.fileInfo.name ?? ""}</td>
                                    <td className="text-right">
                                        <div className='d-flex justify-content-end align-items-center'>
                                        <ToolTipEx tooltip={DEFAULT.DELETE}><span className="material-icons" onClick={e => {
                                                this.handleRemoveFile(item);

                                            }} style={{ cursor: 'pointer' }}>
                                                close
                                            </span></ToolTipEx>
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
                </div>}
                </div>
                    </Col>
       
    }
    render() {
        const { handleModalShowHide, showHideModal, viewEditFlag ,amendment_id } = this.props;
        let {modalProps,formData,fileInfo,isIUDOperation,modal,hasChanged,showLoader}=this.state;
        let loaderConfig={};
        if(isIUDOperation||showLoader){
             loaderConfig={disabled:"disabled"};
        }
        
        return (
            <div>
                 <Modal show={true} size="lg">
                   <div> <Modal.Header className='border-0'>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h5 className="font-weight-bold"> {amendment_id?"Editing an":"Adding an"} Amendment</h5>
                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" onClick={e => {
                                this.onHide();
                            }} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="new-flow-modal" >
                        {showLoader&& <Loader />}
                   <Row>
                            {this.renderInputFields()}
                            {this.renderFileUploadArea()}
                        </Row>
                        {
                            modal&& <BootstrapModal 
                            show={true} 
                            header={modal==="SUCCESS"?"Success":"Error"}
                            body={modal==="SUCCESS"?<>{this.getSuccessmessage()}</>:<><div>An error occured! Please try again later!</div></>}
                            changeShowState={this.changeShowState}
                        />
                        }
                       
                    </Modal.Body>                   
                    <Modal.Footer >
                            <div className="d-flex align-items-center justify-content-center w-100 p-2">
                                <Button {...loaderConfig} onClick={this.onHide} className="mr-2 p-2" style="danger w-100px">Cancel</Button>
                                {
                                  !amendment_id&& isCoordinator(this.context)&& <Button {...loaderConfig} onClick={this.onSave} className="p-2 d-flex align-items-center justify-content-center" style="success w-100px">
                                        <i className="bi bi-plus" ></i><span>Add</span>
                                    </Button>
                                }
                                {
                                  amendment_id&& isCoordinator(this.context)&&  <Button {...loaderConfig} disabled={!hasChanged} onClick={this.onSave} className="p-2 d-flex align-items-center justify-content-center" style="success w-100px">
                                        <span className="material-icons mr-1" style={{fontSize:'19px'}}>save</span><span>SAVE</span>
                                    </Button>
                                }</div>
                    </Modal.Footer>
                    </div>
                </Modal>
            </div>
        )
    }
}


